<template>
  <button type="button" class="color-picker-container" :class="{ [`color-picker-${size}`]: size }">
    <b-form-input :disabled="!isAllow" v-model="color" :placeholder="placeholder" :size="size" />
    <Chrome v-if="isAllow" :value="color" @input="color = $event.hex8" class="color-picker" />
    <div class="color-display" :style="{ backgroundColor: color }"></div>
  </button>
</template>

<script>
import { Sketch, Chrome } from 'vue-color';
import { COLOR_PICKER } from '@/constants/colors';
export default {
  props: {
    placeholder: { type: String, default: 'Color' },
    isAllow: { type: Boolean, default: true },
    size: { type: String, default: 'md' },
    value: { type: String, default: '#000000' },
    left: { type: String, default: 'unset' },
    right: { type: String, default: 'unset' },
  },
  components: { Sketch,Chrome },
  data() {
    return {
      presetColors: COLOR_PICKER,
      checkDisplay: false,
    };
  },
  computed: {
    color: {
      get() {
        return this.value;
      },
      set(color) {
        this.$emit('input', color);
      },
    },
    colorPickerPosition() {
      if (this.left === 'unset' && this.right === 'unset') {
        return { left: 'unset', right: '0' };
      }
      if (this.left !== 'unset' && this.right !== 'unset') {
        return { left: 'unset', right: '0' };
      }
      return { left: this.left, right: this.right };
    },
    colorTablePosition() {
      if (this.left === 'unset' && this.right === 'unset') {
        return { left: 'unset', right: '10px' };
      }
      if (this.left !== 'unset' && this.right !== 'unset') {
        return { left: 'unset', right: '10px' };
      }
      if (this.left !== 'unset' && this.right === 'unset') {
        return { left: `${Number(this.left.replaceAll('px', '')) + 155}px`, right: 'unset' };
      }
      if (this.left === 'unset' && this.right !== 'unset') {
        return { left: 'unset', right: `${Number(this.right.replaceAll('px', '')) + 10}px` };
      }
    },
  },
};
</script>

<style>
.color-picker-container .vc-sketch {
  width: 290px;
}
.color-picker-container .vc-sketch-field .vc-input__input {
  width: 100%;
}
.color-picker-container .vc-sketch-presets {
  display: none;
}
.color-picker-container .vc-sketch-saturation-wrap {
  height: 140px;
  width: calc(100% - 150px);
  padding-bottom: 0;
}
</style>

<style scoped>
.color-picker-container {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  border: none;
  outline: none;
  background-color: transparent;
  cursor: default;
}
.color-picker-container .color-picker {
  position: sticky;
  display: none;
  z-index: 2;
  bottom: 100%;
  flex-direction: column;
  width: 100%;
}
.color-picker-container:focus .color-picker {
  display: block;
}
.color-picker-container:focus-within .color-picker {
  display: block;
}
.color-picker-container .color-table {
  position: absolute;
  bottom: calc(100% + 81px);
  display: none;
  z-index: 3;
  width: 145px;
  height: 140px;
  overflow-y: scroll;
}
.color-picker-container:focus .color-table {
  display: flex;
  flex-wrap: wrap;
}
.color-picker-container:focus-within .color-table {
  display: flex;
  flex-wrap: wrap;
}
.color-picker-container .color-table .preset-color {
  display: inline-block;
  width: 10px;
  height: 10px;
  cursor: pointer;
  border-right: 1px solid #000;
  border-bottom: 1px solid #000;
}
.color-picker-container .color-table .preset-color:nth-child(-n + 14) {
  border-top: 1px solid #000;
}
.color-picker-container .color-table .preset-color:nth-child(14n + 1) {
  border-left: 1px solid #000;
}
.color-picker-container .color-display {
  position: absolute;
  height: calc(100% - 10px);
  top: 5px;
  right: 5px;
  pointer-events: none;
  width: 29px;
}
.color-picker-container.color-picker-sm .color-display {
  width: 20px;
}
</style>