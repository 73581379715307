<template>
  <div class="multi-range">
    <input type="range" :min="range[0]" @mouseup="emitRange" :max="range[1]" @mousemove="changeMax" v-model="rangeMax" ref="ref_ip_lower" :disabled="dis" />
    <input type="range" :min="range[0]" @mouseup="emitRange" :max="range[1]" @mousemove="changeMin" v-model="rangeMin" ref="ref_ip_upper" :disabled="dis" />
    <span class="multi-range-before" :style="{ left: 0, width: (rangeMin / (range[1] - range[0])) * 100 + '%' }"></span>
    <span class="multi-range-color" :style="{ left: (rangeMin / (range[1] - range[0])) * 100 + '%', width: ((rangeMax - rangeMin) / (range[1] - range[0])) * 100 + '%' }"></span>
    <span class="multi-range-after" :style="{ right: 0, width: ((range[1] - rangeMax) / (range[1] - range[0])) * 100 + '%' }"></span>
  </div>
</template>
<script>
export default {
  props: ['value', 'range', 'dis'],
  mounted() {
    if (this.value && this.value.length == 2) {
      this.rangeMin = this.value[0];
      this.rangeMax = this.value[1];
    }
  },
  data() {
    return {
      rangeMax: 30,
      rangeMin: 5,
    };
  },
  computed: {
    dataRange() {
      return [Number(this.rangeMin), Number(this.rangeMax)];
    },
  },
  watch: {
    value(data) {
      this.rangeMax = data[1];
      this.rangeMin = data[0];
    },
    dataRange(data) {
      this.$emit('getRealtimeDataRange', data);
    },
  },
  methods: {
    emitRange() {
      this.$emit('getDataRange', this.dataRange);
    },
    changeMax() {
      if (Number(this.rangeMax) <= Number(this.rangeMin)) {
        this.rangeMin = this.rangeMax;
      }
    },
    changeMin() {
      if (Number(this.rangeMax) <= Number(this.rangeMin)) {
        this.rangeMax = this.rangeMin;
      }
    },
  },
};
</script>
<style scoped lang="scss">
.multi-range {
  position: relative;
  height: 24px;
  max-width: 200px;
  input[type='range'] {
    position: absolute;
    box-sizing: border-box;
    appearance: none;
    width: 100%;
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    overflow: hidden;
    border: 0;
    border-radius: 10px;
    overflow: hidden;
    outline: none;
    background-size: 100% 6px;
    pointer-events: none;
    background-color: transparent;
    z-index: 2;
    &:active,
    &:focus {
      outline: none;
    }
    &::-webkit-slider-thumb {
      height: 14px;
      width: 14px;
      background-color: #397bff;
      position: relative;
      margin: 5px 0;
      border-radius: 50%;
      cursor: pointer;
      appearance: none;
      pointer-events: all;
      box-shadow: 0 1px 4px 0.5px rgba(0, 0, 0, 0.25);
      &::before {
        content: ' ';
        display: block;
        position: absolute;
        top: 13px;
        left: 100%;
        width: 2000px;
        height: 2px;
      }
    }
    &:nth-child(2) {
      background: none;
    }
  }
  &-color {
    background-color: #397bff;
    width: 100%;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    height: 6px;
    border-radius: 10px;
    z-index: 1;
  }
  &-before {
    background: linear-gradient(#9898986b, #d2d2d291) no-repeat center;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    height: 6px;
    z-index: 1;
  }
  &-after {
    background: linear-gradient(#9898986b, #d2d2d291) no-repeat center;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    height: 6px;
    z-index: 1;
  }
}
</style>
