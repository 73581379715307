<template>
  <div class="radio-group">
    <label class="toolbar-title text-primary" v-show="label !== '' && label !== null">{{ label }}</label>
    <div class="radio-container">
      <template>
        <label>
          <span>
            <div class="d-flex align-items-center select-tilte">
              <b-form-checkbox v-model="valueCheck" class="custom-checkbox-icon" switch> </b-form-checkbox>
            </div>
          </span>
        </label>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  props: ['label'],
  data() {
    return {
      valueCheck: true,
    };
  },
  watch: {
    valueCheck(val) {
      this.$emit('visibleAllFilter', val);
    },
  },
};
</script>

<style scoped>
.radio-group {
  width: fit-content;
}

.radio-container {
  border-radius: 0.357rem;
  padding: 4px 0;
  display: flex;
  position: relative;
  user-select: none;
}

.radio-container input {
  display: none;
}

.radio-container label {
  display: flex;
  justify-content: center;
  align-items: center;
  /* width: 40px; */
  height: 32px;
  margin: 0;
  cursor: pointer;
  border-radius: 0.357rem;
  margin-right: 4px;
  white-space: nowrap;
  width: fit-content;
}

.radio-container label:last-of-type {
  margin-right: 0;
}

.radio-container .indicator {
  position: absolute;
  height: 32px;
  background-color: rgba(57, 123, 255, 0.12);
  border-radius: 0.357rem;
  top: 4px;
  pointer-events: none;
  transition: left 0.25s, width 0.25s;
}

.radio-container svg,
.radio-container span {
  transition: color 0.25s;
}
</style>
