<template>
  <div class="option-group option-group-icon" id="option-group" ref="option_group">
    <label class="toolbar-title text-primary" v-if="label">{{ label }}</label>
    <div class="option-container">
      <template>
        <label @click="optionModalFlag = !optionModalFlag" class="option-title"> <feather-icon size="18" icon="SettingsIcon" /></label>
        <div class="option" :class="{ 'show-option': optionModalFlag, 'hidden-option': !optionModalFlag }">
          <div class="option-content card mb-0">
            <slot name="content">
              <div class="option-chart_style">
                <div class="option-chart-title">{{ $t('chart_style') }}</div>
                <div class="option-chart-items_split2">
                  <div class="split2-items layer-collapse-item opt-layout" v-if="typeLayout && typeLayout.length > 0" :title="$t('layout')">
                    <div class="d-flex align-items-center select-tilte">
                      <feather-icon :title="$t('layout')" icon="LayoutIcon" />
                      <p class="mb-0">{{ $t('layout') }}</p>
                    </div>
                    <RadioGroup :moveCount="`option-layout-${moveCountRadioGroupLayout}`" :name="`option-layout`" :label="$t('layout')" :hiddenLable="true" :options="typeLayout" v-model="selectedLayout" class="option-type-layout" />
                  </div>
                  <div class="split2-items layer-collapse-item opt-chart-type" v-if="typeList.length > 0" :title="$t('chart_type')">
                    <div class="d-flex align-items-center select-tilte">
                      <feather-icon :title="$t('chart_type')" icon="ActivityIcon" />
                      <p class="mb-0">{{ $t('chart_type') }}</p>
                    </div>
                    <RadioGroup :moveCount="`option-chart-type-${moveCountRadioGroupChartType}`" :name="`option-chart-type`" :label="$t('chart_type')" :hiddenLable="true" :options="typeList" v-model="typeSelected" />

                    <div style="margin-top: 10px" v-if="sizeWidth != null && nameComponent === 'seasonal_analytics'">
                      <div style="cursor: pointer" class="d-flex align-items-center select-tilte color-title" :title="$t('size_width')">
                        <!-- <feather-icon title="size" icon="GitCommitIcon" />
                        <p class="mb-0">{{ $t('size_width') }}</p> -->
                        <b-form-checkbox @click.native="keypressInput" v-model="opSizeWidth.default" class="custom-checkbox-icon" id="checkbox-auto" switch>
                          <p class="mb-0">{{ $t('size_width') }}</p>
                        </b-form-checkbox>
                      </div>
                      <div class="option-content-size option-content-size-items" :class="{ 'dis-opt': !opSizeWidth.default }">
                        <div class="d-flex justify-content-between align-items-center" style="flex: 1">
                          <div class="option-content-size-item" :title="$t('dot_size')" v-show="sizeWidth != null && sizeWidth.size != null && sizeWidth.size >= 0" style="flex: 1">
                            <label style="height: 26px">{{ $t('dot_size') }}</label>
                            <div class="option-content-size-ip">
                              <b-form-input class="ip_text" @keypress="keypressInput" type="number" :value="opSizeWidth.size" v-model="opSizeWidth.size" :min="DEFAULT_OPTION_MIN_SIZE" :max="DEFAULT_OPTION_MAX_SIZE" required step="1" size="sm"></b-form-input>
                              <b-form-input class="ip_range" @mouseup="emitSizeWidth" v-model="opSizeWidth.size" type="range" lazy :min="DEFAULT_OPTION_MIN_SIZE" :max="DEFAULT_OPTION_MAX_SIZE"></b-form-input>
                            </div>
                          </div>
                          <div class="option-content-size-item" :title="$t('line_width')" v-show="sizeWidth != null && sizeWidth.width != null && sizeWidth.width >= 0" style="flex: 1; margin-left: 5px">
                            <label style="height: 26px">{{ $t('line_width') }}</label>
                            <div class="option-content-size-ip">
                              <b-form-input class="ip_text" @keypress="keypressInput" type="number" :value="opSizeWidth.width" v-model="opSizeWidth.width" :min="DEFAULT_OPTION_MIN_WIDTH" :max="DEFAULT_OPTION_MAX_WIDTH" required step="1" size="sm"></b-form-input>
                              <b-form-input class="ip_range" @mouseup="emitSizeWidth" v-model="opSizeWidth.width" type="range" lazy :min="DEFAULT_OPTION_MIN_WIDTH" :max="DEFAULT_OPTION_MAX_WIDTH"></b-form-input>
                            </div>
                          </div>
                        </div>
                        <div v-show="sizeWidth != null && sizeWidth.range" v-if="opSizeWidth.range && opSizeWidth.range.length == 2" class="size-width-range">
                          <div class="size-width-range-label">({{ opSizeWidth.range[0] }},{{ opSizeWidth.range[1] }})</div>
                          <div class="size-width-range-content">
                            <multi-range :value="opSizeWidth.range" :range="[0, 50]" @getDataRange="getDataRange" @getRealtimeDataRange="getRealtimeDataRange" :dis="!opSizeWidth.default" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="split2-items layer-collapse-item opt-color">
                    <div class="d-flex align-items-center select-tilte color-title" :title="$t('color')">
                      <fill-icon class="color-icon" />
                      <p class="mb-0">{{ $t('color') }}</p>
                    </div>
                    <div class="form-layout" :title="$t('color')">
                      <PalettePicker placeholder="Background" size="sm" :moveCountPalettePicker="moveCountPalettePicker" :timeDelay="timeDelay" :nameComponent="nameComponent" :selectTimeseries="selectTimeseries" />
                    </div>

                    <div v-if="nameComponent === 'scatter'">
                      <div class="d-flex align-items-center select-tilte color-title" :title="$t('size_width')">
                        <!-- <feather-icon title="size" icon="GitCommitIcon" />
                        <p class="mb-0">{{ $t('size_width') }}</p> -->
                        <b-form-checkbox @click.native="keypressInput" v-model="opSizeWidth.default" class="custom-checkbox-icon mt-1" id="checkbox-auto" switch>
                          <p class="mb-0">{{ $t('size_width') }}</p>
                        </b-form-checkbox>
                      </div>
                      <div class="option-content-size option-content-size-items" :class="{ 'dis-opt': !opSizeWidth.default }">
                        <div class="d-flex justify-content-between align-items-center" style="flex: 1">
                          <div class="option-content-size-item" :title="$t('dot_size')" v-show="sizeWidth != null && sizeWidth.size != null && sizeWidth.size >= 0" style="flex: 1">
                            <label>{{ $t('dot_size') }}</label>
                            <div class="option-content-size-ip">
                              <b-form-input class="ip_text" @keypress="keypressInput" type="number" :value="opSizeWidth.size" v-model="opSizeWidth.size" :min="DEFAULT_OPTION_MIN_SIZE" :max="DEFAULT_OPTION_MAX_SIZE" required step="1" size="sm"></b-form-input>
                              <b-form-input class="ip_range" @mouseup="emitSizeWidth" v-model="opSizeWidth.size" type="range" :min="DEFAULT_OPTION_MIN_SIZE" :max="DEFAULT_OPTION_MAX_SIZE"></b-form-input>
                            </div>
                          </div>
                          <div class="option-content-size-item" :title="$t('line_width')" v-show="sizeWidth != null && sizeWidth.width != null && sizeWidth.width >= 0" style="flex: 1; margin-left: 5px">
                            <label>{{ $t('line_width') }}</label>
                            <div class="option-content-size-ip">
                              <b-form-input class="ip_text" @keypress="keypressInput" type="number" :value="opSizeWidth.width" v-model="opSizeWidth.width" :min="DEFAULT_OPTION_MIN_WIDTH" :max="DEFAULT_OPTION_MAX_WIDTH" required step="1" size="sm"></b-form-input>
                              <b-form-input class="ip_range" @mouseup="emitSizeWidth" v-model="opSizeWidth.width" type="range" :min="DEFAULT_OPTION_MIN_WIDTH" :max="DEFAULT_OPTION_MAX_WIDTH"></b-form-input>
                            </div>
                          </div>
                        </div>
                        <div v-show="sizeWidth != null && sizeWidth.range" v-if="opSizeWidth.range && opSizeWidth.range.length == 2" class="size-width-range" :title="$t('size_width')">
                          <div class="size-width-range-label">({{ opSizeWidth.range[0] }},{{ opSizeWidth.range[1] }})</div>
                          <div class="size-width-range-content">
                            <multi-range :value="opSizeWidth.range" :range="[0, 50]" @getDataRange="getDataRange" @getRealtimeDataRange="getRealtimeDataRange" :dis="!opSizeWidth.default" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div v-else>
                      <div class="option-color-group" v-if="outlineGroup && outlineGroup.default != undefined">
                        <div class="option-color-group-check" :title="$t('outline')">
                          <b-form-checkbox @click.native="keypressInput" v-model="opOutlineGroup.default" class="custom-auto-color-group-icon" id="checkbox-auto-color-group" switch> </b-form-checkbox>
                          <label class="option-color-group-label p-0 ml-10" for="checkbox-auto-color-group">{{ $t('outline').toUpperCase() }}</label>
                        </div>
                        <!-- <div :class="{ 'dis-opt': !opOutlineGroup.default }">
                          <b-button @click.stop="saveOutlineGroup" :title="$t('save')" variant="success" size="sm" style="padding: 6px 10px">{{ $t('save') }}</b-button>
                        </div> -->
                      </div>
                      <div v-if="outlineGroup && outlineGroup.default != undefined" :class="{ 'dis-opt': !opOutlineGroup.default }" class="option-color-groups">
                        <div class="option-color-group-row d-flex justify-content-between">
                          <div class="option-color-group-row-item" :title="$t('outline_width')">
                            <div class="option-color-group-row-item-icon"><weight-icon style="width: 18px; height: 18px" /></div>
                            <div class="option-color-group-row-item-num">
                              <b-form-input class="ip_text" type="number" @change="saveOutlineGroup" :value="opOutlineGroup.width" v-model="opOutlineGroup.width" min="0" max="10" required step="1" size="sm"></b-form-input>
                              <b-form-input class="ip_range" @change="saveOutlineGroup" v-model="opOutlineGroup.width" type="range" min="0" max="10"></b-form-input>
                            </div>
                          </div>
                          <div class="option-color-group-row-item" :title="$t('outline_opacity')">
                            <div class="option-color-group-row-item-icon"><opacity-icon style="width: 18px; height: 18px" /></div>
                            <div class="option-color-group-row-item-num">
                              <b-form-input class="ip_text" type="number" @change="saveOutlineGroup" :value="opOutlineGroup.opacity" v-model="opOutlineGroup.opacity" min="0" max="1" required step="0.1" size="sm"></b-form-input>
                              <b-form-input class="ip_range" @change="saveOutlineGroup" v-model="opOutlineGroup.opacity" type="range" min="0.0" max="1.0" step="0.01"></b-form-input>
                            </div>
                          </div>
                        </div>
                        <div class="option-color-group-row d-flex justify-content-between">
                          <div class="option-color-group-row-item item-color" :title="$t('outline_color')">
                            <div class="option-color-group-row-item-icon"><stroke-icon style="width: 18px; height: 18px" /></div>
                            <color-picker v-model="opOutlineGroup.color" @input="saveOutlineGroup" placeholder="Color" size="sm" :right="'-4px'" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="split2-items layer-collapse-item opt-size-width" v-if="sizeWidth != null && nameComponent !== 'seasonal_analytics'">
                    <div v-if="nameComponent === 'scatter'">
                      <div class="option-color-group" v-if="outlineGroup && outlineGroup.default != undefined" :title="$t('outline')">
                        <div class="option-color-group-check">
                          <b-form-checkbox @click.native="keypressInput" v-model="opOutlineGroup.default" class="custom-auto-color-group-icon" id="checkbox-auto-color-group" switch> </b-form-checkbox>
                          <label class="option-color-group-label p-0 ml-10" for="checkbox-auto-color-group">{{ $t('outline').toUpperCase() }}</label>
                        </div>
                        <!-- <div :class="{ 'dis-opt': !opOutlineGroup.default }">
                          <b-button @click.stop="saveOutlineGroup" :title="$t('save')" variant="success" size="sm" style="padding: 6px 10px">{{ $t('save') }}</b-button>
                        </div> -->
                      </div>
                      <div v-if="outlineGroup && outlineGroup.default != undefined" :class="{ 'dis-opt': !opOutlineGroup.default }" class="option-color-groups">
                        <div class="option-color-group-row d-flex justify-content-between">
                          <div class="option-color-group-row-item" :title="$t('outline_width')">
                            <div class="option-color-group-row-item-icon"><weight-icon style="width: 18px; height: 18px" /></div>
                            <div class="option-color-group-row-item-num">
                              <b-form-input class="ip_text" @change="saveOutlineGroup" type="number" :value="opOutlineGroup.width" v-model="opOutlineGroup.width" min="0" max="10" required step="1" size="sm"></b-form-input>
                              <b-form-input class="ip_range" @change="saveOutlineGroup" v-model="opOutlineGroup.width" type="range" min="0" max="10"></b-form-input>
                            </div>
                          </div>
                          <div class="option-color-group-row-item" :title="$t('outline_opacity')">
                            <div class="option-color-group-row-item-icon"><opacity-icon style="width: 18px; height: 18px" /></div>
                            <div class="option-color-group-row-item-num">
                              <b-form-input class="ip_text" @change="saveOutlineGroup" type="number" :value="opOutlineGroup.opacity" v-model="opOutlineGroup.opacity" min="0" max="1" required step="0.1" size="sm"></b-form-input>
                              <b-form-input class="ip_range" @change="saveOutlineGroup" v-model="opOutlineGroup.opacity" type="range" min="0.0" max="1.0" step="0.01"></b-form-input>
                            </div>
                          </div>
                        </div>
                        <div class="option-color-group-row d-flex justify-content-between">
                          <div class="option-color-group-row-item item-color" :title="$t('outline_color')">
                            <div class="option-color-group-row-item-icon"><stroke-icon style="width: 18px; height: 18px" /></div>
                            <color-picker v-model="opOutlineGroup.color" @input="saveOutlineGroup" placeholder="Color" size="sm" :right="'-4px'" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div v-else>
                      <div style="cursor: pointer" class="d-flex align-items-center select-tilte color-title" :title="$t('size_width')">
                        <!-- <feather-icon title="size" icon="GitCommitIcon" />
                        <p class="mb-0">{{ $t('size_width') }}</p> -->
                        <b-form-checkbox @click.native="keypressInput" v-model="opSizeWidth.default" class="custom-checkbox-icon" id="checkbox-auto" switch>
                          <p class="mb-0">{{ $t('size_width') }}</p>
                        </b-form-checkbox>
                      </div>
                      <div class="option-content-size option-content-size-items" :class="{ 'dis-opt': !opSizeWidth.default }">
                        <div class="d-flex justify-content-between align-items-center" style="flex: 1">
                          <div class="option-content-size-item" :title="$t('dot_size')" v-show="sizeWidth != null && sizeWidth.size != null && sizeWidth.size >= 0" style="flex: 1">
                            <label>{{ $t('dot_size') }}</label>
                            <div class="option-content-size-ip">
                              <b-form-input class="ip_text" @keypress="keypressInput" type="number" :value="opSizeWidth.size" v-model="opSizeWidth.size" :min="DEFAULT_OPTION_MIN_SIZE" :max="DEFAULT_OPTION_MAX_SIZE" required step="1" size="sm"></b-form-input>
                              <b-form-input class="ip_range" @mouseup="emitSizeWidth" v-model="opSizeWidth.size" type="range" lazy :min="DEFAULT_OPTION_MIN_SIZE" :max="DEFAULT_OPTION_MAX_SIZE"></b-form-input>
                            </div>
                          </div>
                          <div class="option-content-size-item" :title="$t('line_width')" v-show="sizeWidth != null && sizeWidth.width != null && sizeWidth.width >= 0" style="flex: 1; margin-left: 5px">
                            <label>{{ $t('line_width') }}</label>
                            <div class="option-content-size-ip">
                              <b-form-input class="ip_text" @keypress="keypressInput" type="number" :value="opSizeWidth.width" v-model="opSizeWidth.width" :min="DEFAULT_OPTION_MIN_WIDTH" :max="DEFAULT_OPTION_MAX_WIDTH" required step="1" size="sm"></b-form-input>
                              <b-form-input class="ip_range" @mouseup="emitSizeWidth" v-model="opSizeWidth.width" type="range" lazy :min="DEFAULT_OPTION_MIN_WIDTH" :max="DEFAULT_OPTION_MAX_WIDTH"></b-form-input>
                            </div>
                          </div>
                        </div>
                        <div v-show="sizeWidth != null && sizeWidth.range" v-if="opSizeWidth.range && opSizeWidth.range.length == 2" class="size-width-range">
                          <div class="size-width-range-label">({{ opSizeWidth.range[0] }},{{ opSizeWidth.range[1] }})</div>
                          <div class="size-width-range-content">
                            <multi-range :value="opSizeWidth.range" :range="[0, 50]" @getDataRange="getDataRange" @getRealtimeDataRange="getRealtimeDataRange" :dis="!opSizeWidth.default" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="split2-items layer-collapse-item opt-shape" v-show="shape">
                    <div class="d-flex align-items-center select-tilte" :title="$t('shape')">
                      <feather-icon icon="HexagonIcon" />
                      <p class="mb-0">{{ $t('shape') }}</p>
                    </div>
                    <div class="">
                      <div class="d-flex justify-content-between">
                        <label class="shape-seleted-label" for="" :title="$t('shape_selected')">{{ $t('shape_selected') }}</label>
                        <div>
                          <span :title="$t('reset')" @click.stop="resetMarkerSymbol" style="margin-right: 5px"><feather-icon icon="RefreshCwIcon" size="20" style="cursor: pointer" /></span>
                          <span :title="$t('save')" @click.stop="saveMarkerSymbol"><feather-icon icon="SaveIcon" size="20" style="cursor: pointer" /></span>
                        </div>
                      </div>
                      <div id="shape-selected" class="p-0 m-0">
                        <div class="chart-shape-selected" ref="refChartShapeSelected"></div>
                      </div>
                    </div>
                    <div class="form-layout">
                      <div class="chart-shape-plotly" ref="refChartShapePlotly"></div>
                    </div>
                  </div>
                </div>
                <div class="layer-collapse-item opt-font-chart option-chart-items_split3">
                  <div class="split3-items">
                    <div class="d-flex align-items-center select-tilte label-font-chart">
                      <family-icon style="width: 14px; height: 14px; margin-right: 10px" />
                      <p class="mb-0">{{ $t('font_family') }}</p>
                    </div>
                    <b-form-group :label="``">
                      <vue-select :options="chartFontFamilyChoices" v-model="chartFontFamily" />
                    </b-form-group>
                  </div>
                  <div class="split3-items">
                    <div class="d-flex align-items-center select-tilte label-font-chart">
                      <size-icon style="width: 14px; height: 14px; margin-right: 10px" />
                      <p class="mb-0">{{ $t('text_size') }}</p>
                    </div>
                    <b-form-group :label="``">
                      <b-form-input v-model="chartTextSize" :placeholder="$t('text_size')" type="number" />
                    </b-form-group>
                  </div>
                  <div class="split3-items">
                    <div class="d-flex align-items-center select-tilte label-font-chart">
                      <color-icon style="width: 14px; height: 14px; margin-right: 10px" />
                      <p class="mb-0">{{ $t('text_color') }}</p>
                    </div>
                    <b-form-group :label="``">
                      <ColorPicker v-model="chartTextColor" :placeholder="$t('text_color')" />
                    </b-form-group>
                  </div>
                </div>
                <div v-if="nameComponent !== 'scatter_three'" class="option-chart-items_split3">
                  <div class="split3-items layer-collapse-item hidden-collapse-icon" :disabled="true" v-if="opRotate != undefined" :title="$t('rotate')">
                    <div @click="opRotate = !opRotate" style="cursor: pointer" class="d-flex align-items-center select-tilte">
                      <b-form-checkbox v-model="opRotate" class="custom-checkbox-icon custom-checkbox-icon-switch" switch @click.native.stop>
                        <span class="switch-icon-right">
                          <feather-icon icon="ArrowUpIcon" />
                        </span>
                        <span class="switch-icon-left">
                          <feather-icon :icon="nameComponent === 'histogram' || nameComponent === 'lines' ? 'ArrowDownIcon' : 'ArrowRightIcon'" />
                        </span>
                      </b-form-checkbox>
                      <p class="mb-0">{{ $t('rotate') }}</p>
                    </div>
                  </div>
                  <div class="split3-items layer-collapse-item hidden-collapse-icon" :disabled="true" v-if="opSummary != undefined" :title="$t('summary')">
                    <div @click="opSummary = !opSummary" style="cursor: pointer" class="d-flex align-items-center select-tilte">
                      <b-form-checkbox v-model="opSummary" class="custom-checkbox-icon custom-checkbox-icon-switch" switch @click.native.stop>
                        <span class="switch-icon-left">
                          <feather-icon icon="EyeIcon" />
                        </span>
                        <span class="switch-icon-right">
                          <feather-icon icon="EyeOffIcon" />
                        </span>
                      </b-form-checkbox>
                      <p class="mb-0">{{ $t('summary') }}</p>
                    </div>
                  </div>
                  <div class="split3-items layer-collapse-item hidden-collapse-icon" :disabled="true" v-if="opUnderlying != undefined" :title="$t('underlying')">
                    <div @click="opUnderlying = !opUnderlying" style="cursor: pointer" class="d-flex align-items-center select-tilte">
                      <b-form-checkbox v-model="opUnderlying" class="custom-checkbox-icon custom-checkbox-icon-switch" switch @click.native.stop>
                        <span class="switch-icon-left">
                          <feather-icon icon="EyeIcon" />
                        </span>
                        <span class="switch-icon-right">
                          <feather-icon icon="EyeOffIcon" />
                        </span>
                      </b-form-checkbox>
                      <p class="mb-0">{{ $t('underlying') }}</p>
                    </div>
                  </div>
                  <div class="split3-items layer-collapse-item hidden-collapse-icon" :disabled="true" :title="$t('legend')">
                    <div @click="opShowLegend = !opShowLegend" style="cursor: pointer" class="d-flex align-items-center select-tilte">
                      <b-form-checkbox v-model="opShowLegend" class="custom-checkbox-icon custom-checkbox-icon-switch" switch @click.native.stop>
                        <span class="switch-icon-left">
                          <feather-icon icon="EyeIcon" />
                        </span>
                        <span class="switch-icon-right">
                          <feather-icon icon="EyeOffIcon" />
                        </span>
                      </b-form-checkbox>
                      <p class="mb-0">{{ $t('legend') }}</p>
                    </div>
                  </div>
                  <div class="split3-items layer-collapse-item hidden-collapse-icon" :disabled="true" :title="$t('add_year')">
                    <div @click="opShowYear = !opShowYear" style="cursor: pointer" class="d-flex align-items-center select-tilte">
                      <b-form-checkbox v-model="opShowYear" class="custom-checkbox-icon custom-checkbox-icon-switch" switch @click.native.stop>
                        <span class="switch-icon-left">
                          <feather-icon icon="EyeIcon" />
                        </span>
                        <span class="switch-icon-right">
                          <feather-icon icon="EyeOffIcon" />
                        </span>
                      </b-form-checkbox>
                      <p class="mb-0">{{ $t('add_year') }}</p>
                    </div>
                  </div>
                  <div class="split3-items layer-collapse-item hidden-collapse-icon" :disabled="true" :title="$t('editable')">
                    <div @click="editable = !editable" style="cursor: pointer" class="d-flex align-items-center select-tilte">
                      <b-form-checkbox v-model="editable" class="custom-checkbox-icon custom-checkbox-icon-switch" switch @click.native.stop> </b-form-checkbox>
                      <p class="mb-0">{{ $t('editable') }}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div :style="{ 'margin-top': nameComponent === 'scatter_three' ? '1rem' : '1.5rem' }" class="option-chart_data">
                <div class="option-chart-title">{{ $t('data') }}</div>
                <div v-if="nameComponent === 'seasonal_analytics'" class="option-chart-items_split2">
                  <div class="split2-items layer-collapse-item opt-chart-scale" :title="$t('resample_boxplot_seasonal')">
                    <div class="d-flex align-items-center select-tilte">
                      <feather-icon :title="$t('resample_boxplot_seasonal')" icon="FastForwardIcon" class="fast-forward-icon" />
                      <p class="mb-0">{{ $t('resample_boxplot_seasonal') }}</p>
                    </div>
                    <RadioGroup :moveCount="`option-resample-seasonal-${moveCountResampleSeasonal}`" :name="`option-resample-seasonal`" :toggle="false" :hiddenLable="true" :label="$t('resample_boxplot_seasonal')" :options="resampleSeasonalOption" v-model="resampleSeasonal" />
                  </div>
                </div>
                <div class="option-chart-items_split2">
                  <div class="split2-items layer-collapse-item opt-chart-scale" v-if="showScale" :title="$t('scale')">
                    <div class="d-flex align-items-center select-tilte">
                      <feather-icon :title="$t('scale')" icon="Minimize2Icon" />
                      <p class="mb-0">{{ $t('scale') }}</p>
                    </div>
                    <RadioGroup :moveCount="`option-scale-${moveCountRadioGroupScale}`" :name="`option-scale`" :toggle="true" :hiddenLable="true" :label="$t('scale')" :options="scaleChoices" v-model="scaleOption" />
                  </div>
                  <div class="split2-items layer-collapse-item opt-fill-data" v-if="showFillData" :title="$t('fill_data')">
                    <div class="d-flex align-items-center select-tilte">
                      <feather-icon :title="$t('fill_data')" icon="TrendingUpIcon" />
                      <p class="mb-0">{{ $t('fill_data') }}</p>
                    </div>
                    <RadioGroup :moveCount="`option-fill-data-${moveCountRadioGroupFillData}`" :name="`option-fill-data`" :toggle="true" :hiddenLable="true" :label="$t('fill_data')" :options="fillDataArr" v-model="opFillData" />
                  </div>
                </div>
                <div class="full-items layer-collapse-item opt-chart-resample" v-if="resample && resample.show == true" :disabled="!opResample.autoResample" :title="opResample.autoResample ? $t('resample_default') : $t('not_resample_default')">
                  <div @click="opResample.autoResample = !opResample.autoResample" style="cursor: pointer" class="d-flex align-items-center select-tilte color-title">
                    <feather-icon icon="FastForwardIcon" class="fast-forward-icon" />
                    <p class="mb-0">{{ $t('resample_default') }}</p>
                    <b-form-checkbox v-model="opResample.autoResample" class="custom-checkbox-icon ml-1" @click.native.stop="keypressResample" switch> </b-form-checkbox>
                  </div>
                  <div :class="{ 'dis-opt': !opResample.autoResample }" class="d-flex align-items-center">
                    <div class="resample-autoGroup">
                      <div class="d-flex align-items-center resample-autoGroup-methods">
                        <b-form-checkbox v-model="opResample.autoGroup" id="resample-autogroup-option" class="custom-checkbox-icon" @click.native.stop="keypressResample" :disabled="!opResample.autoResample" switch> </b-form-checkbox>
                        <label class="resample-label p-0" for="resample-autogroup-option">
                          {{ $t('auto_resample') }}
                          <span class="methods-resample" v-if="opResample.autoGroup">
                            <span :class="{ 'methods-resample-active': opMethodsResample == 'hourly' }">({{ $t('hourly') }}</span>
                            <span :class="{ 'methods-resample-active': opMethodsResample == 'daily' }">> {{ $t('daily') }}</span>
                            <span :class="{ 'methods-resample-active': opMethodsResample == 'monthly' }">> {{ $t('monthly') }}</span>
                            <span :class="{ 'methods-resample-active': opMethodsResample == 'yearly' }">> {{ $t('yearly') }}</span>
                            <span :class="{ 'methods-resample-active': opMethodsResample == 'none' }">> {{ $t('none') }})</span>
                          </span>
                          <span class="methods-resample methods-resample-active" v-else> (coarsen={{ opResample.value }}) </span>
                        </label>
                      </div>
                      <div class="resample-input" :class="{ 'dis-opt': opResample.autoGroup == true }">
                        <b-form-input v-if="resample" class="ip_text" @keypress="keypressResample" type="number" :value="opResample.value" v-model="opResample.value" min="1" :max="opResample.max" required step="1" size="sm"></b-form-input>
                        <b-form-input v-if="resample" class="ip_range" @mouseup="emitResample" v-model="opResample.value" type="range" min="1" :max="opResample.max"></b-form-input>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </slot>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>
<script>
const _ = require('lodash');
import { ThemeConfig } from '@/mixins/ThemeMixin';
import ScaleMixin from '@/mixins/ScaleMixin';
import RadioGroup from './RadioGroup.vue';
import Plotly from 'plotly.js-dist';
import FeatherIcon from '../../../@core/components/feather-icon/FeatherIcon.vue';
import AppCollapse from '@/@core/components/app-collapse/AppCollapse.vue';
import AppCollapseItem from '@/@core/components/app-collapse/AppCollapseItem.vue';
import PalettePicker from './PalettePickerV2.vue';
import Option from '../tabs/boxplot/Option.vue';
import MultiRange from './MultiRange.vue';
import ColorPicker from './ColorPicker.vue';

// import svg
import FillIcon from '/static/images/layer-config/prepend/fill2.svg';
import WeightIcon from '/static/images/layer-config/prepend/weight2.svg';
import StrokeIcon from '/static/images/layer-config/prepend/stroke1.svg';
import OpacityIcon from '/static/images/layer-config/prepend/opacity.svg';
// icon
import FamilyIcon from '/static/images/layer-config/prepend/font-family.svg';
import ColorIcon from '/static/images/layer-config/prepend/font-color.svg';
import SizeIcon from '/static/images/layer-config/prepend/font-size.svg';

const LAYOUT = {
  margin: { l: 0, r: 0, b: 0, t: 0 },
  width: 300,
  height: 800,
  xaxis: {
    visible: false,
    showgrid: false,
    zeroline: false,
    fixedrange: true,
  },
  yaxis: {
    visible: false,
    showgrid: false,
    zeroline: false,
    autorange: 'reversed',
    fixedrange: true,
  },
  showlegend: false,
  plot_bgcolor: 'transparent',
  paper_bgcolor: 'transparent',
};
const CONFIG = {
  displayModeBar: false,
};
const DEFAULT_COLOR_HIDEN_SYMBOL = '#82868b';
const DEFAULT_OPACITY_HIDEN_SYMBOL = 0.5;
const DEFAULT_COLOR_SHOW_SYMBOL = 'rgb(17, 157, 255)';
const DEFAULT_OPTION_MAX_SIZE = 50;
const DEFAULT_OPTION_MAX_WIDTH = 10;
const DEFAULT_OPTION_MIN_SIZE = 1;
const DEFAULT_OPTION_MIN_WIDTH = 1;
// const DEFAULT_MARKER_COLOR
export default {
  props: ['label', 'options', 'toggle', 'nameComponent', 'typeList', 'optionsSummary', 'shape', 'typeLayout', 'resample', 'selectTimeseries', 'showScale', 'showFillData'],
  components: { FeatherIcon, AppCollapse, AppCollapseItem, FillIcon, PalettePicker, RadioGroup, Option, MultiRange, WeightIcon, StrokeIcon, ColorPicker, OpacityIcon, FamilyIcon, ColorIcon, SizeIcon },
  mixins: [ScaleMixin, ThemeConfig],
  data() {
    return {
      rangeCheck: 0,
      DEFAULT_OPTION_MAX_SIZE: DEFAULT_OPTION_MAX_SIZE,
      DEFAULT_OPTION_MAX_WIDTH: DEFAULT_OPTION_MAX_WIDTH,
      DEFAULT_OPTION_MIN_SIZE: DEFAULT_OPTION_MIN_SIZE,
      DEFAULT_OPTION_MIN_WIDTH: DEFAULT_OPTION_MIN_WIDTH,
      symbols: [],
      symbolSelected: [],
      rowLenSymbols: 8,
      dataSymbols: [],
      dataSymbolsSelected: [],
      showOption: false,
      status: false,
      optionModalFlag: false,
      opSizeWidth: {
        size: null,
        width: null,
        range: null,
        default: false,
      },
      timeOutDelay: null,
      timeDelay: 250,
      opResample: {
        value: 1,
        max: 1,
        autoGroup: true,
        autoResample: false,
      },
      opOutlineGroup: {
        default: false,
        width: 0,
        color: 'rgb(140 138 138)',
        opacity: 0,
      },
      opMethodsResample: null,

      // move Radio label
      moveCountRadioGroupLayout: 0,
      moveCountRadioGroupChartType: 0,
      moveCountRadioGroupScale: 0,
      moveCountResampleSeasonal: 0,
      moveCountRadioGroupFillData: 0,
      moveCountPalettePicker: 0,

      //chart
      chartFontFamilyChoices: ['Noto Sans JP', 'Calibri', 'Sans Serif', 'Serif', 'Cursive', 'Monospace'],
    };
  },
  mounted() {
    try {
      if (!this.chartTextColor) {
        const color = this.theme === 'dark' ? '#d0d2d6' : '#444';
        this.$store.commit('settings/UPDATE_SETTINGS', { chartTextColor: color });
      }
    } catch {}

    let self = this;
    this.getOption();
    if (this.symbolSelected.length > 0) {
      this.getSymbols();
      this.getDataSymbols(this.symbols);
      this.mergeSymbolsSelectedInDataSymbols(this.symbolSelected);
      this.getDataSymbolsSelected(this.symbolSelected);
      this.symbolChart(this.$refs.refChartShapePlotly, this.dataSymbols);
      this.symbolSelectedChart(this.$refs.refChartShapeSelected, this.dataSymbolsSelected);
      this.$refs.refChartShapePlotly.on('plotly_click', function (data) {
        let posY = data.points[0].x;
        let posX = data.points[0].y == 0 ? 0 : data.points[0].y / 10;
        let id = posX * self.rowLenSymbols + posY;
        let indexFirst = self.symbolSelected.indexOf(id);
        let indexLast = self.symbolSelected.lastIndexOf(id);
        if (indexFirst > -1 && indexFirst == indexLast) {
          if (self.dataSymbols[id].opacity === 1) {
            if (self.symbolSelected.length > 1) {
              self.dataSymbols[id].opacity = DEFAULT_OPACITY_HIDEN_SYMBOL;
              self.dataSymbols[id].marker.color = DEFAULT_COLOR_HIDEN_SYMBOL;
              self.symbolSelected.splice(indexFirst, 1);
            }
          }
        } else {
          if (self.dataSymbols[id].opacity !== 1) {
            self.dataSymbols[id].opacity = 1;
            self.dataSymbols[id].marker.color = DEFAULT_COLOR_SHOW_SYMBOL;
            self.symbolSelected.push(id);
          }
        }
        LAYOUT.height = 800;
        Plotly.redraw(self.$refs.refChartShapePlotly, self.dataSymbols, LAYOUT, CONFIG);
      });
    }
    this.$nextTick(() => {
      if (self.$refs.option_group) {
        self.clickOutsideDiv(self.$refs.option_group);
      }
    });
  },
  computed: {
    resampleSeasonalOption() {
      return [
        { text: this.$i18n.t('original'), value: 'origin' },
        { text: this.$i18n.t('monthly_average'), value: 'monthlyAverage' },
      ];
    },
    resampleSeasonal: {
      get() {
        return this.$store.state.tabs[this.nameComponent].resampleSeasonal;
      },
      set(resampleSeasonal) {
        this.$store.commit(`tabs/SET_${this.nameComponent.toUpperCase()}`, { resampleSeasonal });
      },
    },
    fillDataArr() {
      return [
        { text: this.$i18n.t('linear_fill'), value: 'linear-fill' },
        { text: this.$i18n.t('front_fill'), value: 'front-fill' },
        { text: this.$i18n.t('na_zero'), value: 'na-zero' },
      ];
    },
    editable: {
      get() {
        return this.$store.state.tabs[this.nameComponent].editable;
      },
      set(editable) {
        this.$store.commit(`tabs/SET_${this.nameComponent.toUpperCase()}`, { editable });
      },
    },
    symbolsSelectedChange() {
      return this.symbolSelected.length;
    },
    typeSelected: {
      get() {
        let data = this.$store.state.tabs[this.nameComponent];
        let typeSelected;
        switch (this.selectTimeseries) {
          case 'xy1': {
            typeSelected = data.arrayDatasourceSelect[0].typeSelected;
            break;
          }
          case 'xy2': {
            typeSelected = data.arrayDatasourceSelect[1].typeSelected;
            break;
          }
          case 'xy3': {
            typeSelected = data.arrayDatasourceSelect[2].typeSelected;
            break;
          }
          case 'xy4': {
            typeSelected = data.arrayDatasourceSelect[3].typeSelected;
            break;
          }
          default: {
            typeSelected = data.typeSelected;
            break;
          }
        }
        return typeSelected;
      },
      set(typeSelected) {
        let arrayDatasourceSelect = this.$store.state.tabs[this.nameComponent].arrayDatasourceSelect;
        if (this.selectTimeseries) {
          switch (this.selectTimeseries) {
            case 'xy1': {
              arrayDatasourceSelect[0].typeSelected = typeSelected;
              break;
            }
            case 'xy2': {
              arrayDatasourceSelect[1].typeSelected = typeSelected;
              break;
            }
            case 'xy3': {
              arrayDatasourceSelect[2].typeSelected = typeSelected;
              break;
            }
            case 'xy4': {
              arrayDatasourceSelect[3].typeSelected = typeSelected;
              break;
            }
          }
          this.$store.commit(`tabs/SET_${this.nameComponent.toUpperCase()}`, { arrayDatasourceSelect });
        }
        this.$store.commit(`tabs/SET_${this.nameComponent.toUpperCase()}`, { typeSelected });
      },
    },
    selectedLayout: {
      get() {
        return this.$store.state.tabs[this.nameComponent].selectedLayout;
      },
      set(selectedLayout) {
        this.$store.commit(`tabs/SET_${this.nameComponent.toUpperCase()}`, { selectedLayout });
      },
    },
    opFillData: {
      get() {
        let data = this.$store.state.tabs[this.nameComponent];
        let fillData;
        switch (this.selectTimeseries) {
          case 'xy1': {
            fillData = data.arrayDatasourceSelect[0].fillData;
            break;
          }
          case 'xy2': {
            fillData = data.arrayDatasourceSelect[1].fillData;
            break;
          }
          case 'xy3': {
            fillData = data.arrayDatasourceSelect[2].fillData;
            break;
          }
          case 'xy4': {
            fillData = data.arrayDatasourceSelect[3].fillData;
            break;
          }
          default: {
            fillData = data.fillData;
            break;
          }
        }
        return fillData;
      },
      set(fillData) {
        let arrayDatasourceSelect = this.$store.state.tabs[this.nameComponent].arrayDatasourceSelect;
        if (this.selectTimeseries) {
          switch (this.selectTimeseries) {
            case 'xy1': {
              arrayDatasourceSelect[0].fillData = fillData;
              break;
            }
            case 'xy2': {
              arrayDatasourceSelect[1].fillData = fillData;
              break;
            }
            case 'xy3': {
              arrayDatasourceSelect[2].fillData = fillData;
              break;
            }
            case 'xy4': {
              arrayDatasourceSelect[3].fillData = fillData;
              break;
            }
          }
          this.$store.commit(`tabs/SET_${this.nameComponent.toUpperCase()}`, { arrayDatasourceSelect });
        }
        this.$store.commit(`tabs/SET_${this.nameComponent.toUpperCase()}`, { fillData });
      },
    },
    opShowLegend: {
      get() {
        return this.$store.state.tabs[this.nameComponent].legend;
      },
      set(legend) {
        this.$store.commit(`tabs/SET_${this.nameComponent.toUpperCase()}`, { legend });
      },
    },
    opShowYear: {
      get() {
        return this.$store.state.tabs[this.nameComponent].year;
      },
      set(year) {
        this.$store.commit(`tabs/SET_${this.nameComponent.toUpperCase()}`, { year });
      },
    },
    opRotate: {
      get() {
        return this.$store.state.tabs[this.nameComponent].rotate;
      },
      set(rotate) {
        this.$store.commit(`tabs/SET_${this.nameComponent.toUpperCase()}`, { rotate });
      },
    },
    opSummary: {
      get() {
        return this.$store.state.tabs[this.nameComponent].summary;
      },
      set(summary) {
        this.$store.commit(`tabs/SET_${this.nameComponent.toUpperCase()}`, { summary });
      },
    },
    sizeWidth() {
      return this.$store.state.tabs[this.nameComponent].sizeWidth;
    },
    outlineGroup() {
      return this.$store.state.tabs[this.nameComponent].outlineGroup;
    },
    markerSymbolDefault() {
      return this.$store.state.settings.markerSymbol;
    },
    opUnderlying: {
      get() {
        return this.$store.state.tabs[this.nameComponent].underlying;
      },
      set(underlying) {
        this.$store.commit(`tabs/SET_${this.nameComponent.toUpperCase()}`, { underlying });
      },
    },
    chartFontFamily: {
      get() {
        try {
          return this.$store.state.tabs[this.nameComponent].chartFontFamily;
        } catch {
          return this.$store.state.settings.chartFontFamily;
        }
      },
      set(chartFontFamily) {
        this.$store.commit(`tabs/SET_${this.nameComponent.toUpperCase()}`, { chartFontFamily });
      },
    },
    chartTextSize: {
      get() {
        try {
          return this.$store.state.tabs[this.nameComponent].chartTextSize;
        } catch {
          return this.$store.state.settings.chartTextSize;
        }
      },
      set(chartTextSize) {
        this.$store.commit(`tabs/SET_${this.nameComponent.toUpperCase()}`, { chartTextSize });
      },
    },
    chartTextColor: {
      get() {
        try {
          if (!this.$store.state.tabs[this.nameComponent].chartTextColor) {
            this.chartTextColor = this.isDark ? '#d0d2d6' : '#444';
          }
          return this.$store.state.tabs[this.nameComponent].chartTextColor;
        } catch {
          return this.$store.state.settings.chartTextColor;
        }
      },
      set(chartTextColor) {
        this.$store.commit(`tabs/SET_${this.nameComponent.toUpperCase()}`, { chartTextColor });
      },
    },
  },
  watch: {
    sizeWidth(sizeWidth) {
      this.opSizeWidth = sizeWidth;
    },
    outlineGroup(outlineGroup) {
      this.opOutlineGroup = outlineGroup;
    },
    selectTimeseries(selectTimeseries) {
      let arrayDatasourceSelect = this.$store.state.tabs[this.nameComponent].arrayDatasourceSelect;
      switch (selectTimeseries) {
        case 'xy1': {
          this.opSizeWidth = arrayDatasourceSelect[0].sizeWidth;
          this.opResample = arrayDatasourceSelect[0].resample;
          break;
        }
        case 'xy2': {
          this.opSizeWidth = arrayDatasourceSelect[1].sizeWidth;
          this.opResample = arrayDatasourceSelect[1].resample;
          break;
        }
        case 'xy3': {
          this.opSizeWidth = arrayDatasourceSelect[2].sizeWidth;
          this.opResample = arrayDatasourceSelect[2].resample;
          break;
        }
        case 'xy4': {
          this.opSizeWidth = arrayDatasourceSelect[3].sizeWidth;
          this.opResample = arrayDatasourceSelect[3].resample;
          break;
        }
        default: {
          this.opSizeWidth = arrayDatasourceSelect[0].sizeWidth;
          this.opResample = arrayDatasourceSelect[0].resample;
          break;
        }
      }
    },
    'opSizeWidth.width'(val) {
      setTimeout(() => {
        if (val <= DEFAULT_OPTION_MIN_WIDTH) {
          this.opSizeWidth.width = DEFAULT_OPTION_MIN_WIDTH;
        }
        if (val >= DEFAULT_OPTION_MAX_WIDTH) {
          this.opSizeWidth.width = DEFAULT_OPTION_MAX_WIDTH;
        }
      }, 50);
    },
    'opSizeWidth.size'(val) {
      setTimeout(() => {
        if (val <= DEFAULT_OPTION_MIN_SIZE) {
          this.opSizeWidth.size = DEFAULT_OPTION_MIN_SIZE;
        }
        if (val >= DEFAULT_OPTION_MAX_SIZE) {
          this.opSizeWidth.size = DEFAULT_OPTION_MAX_SIZE;
        }
      }, 50);
    },
    'opOutlineGroup.width'(val) {
      setTimeout(() => {
        if (val <= 0) {
          this.opOutlineGroup.width = 0;
        }
        if (val >= 10) {
          this.opOutlineGroup.width = 10;
        }
      }, 50);
    },
    'opOutlineGroup.opacity'(val) {
      setTimeout(() => {
        if (val <= 0) {
          this.opOutlineGroup.opacity = 0;
        }
        if (val >= 1) {
          this.opOutlineGroup.opacity = 1;
        }
      }, 50);
    },
    symbolsSelectedChange() {
      this.getDataSymbolsSelected(this.symbolSelected);
      this.symbolSelectedChart(this.$refs.refChartShapeSelected, this.dataSymbolsSelected);
    },
    'opOutlineGroup.default'() {
      clearTimeout(this.timeOutDelay);
      this.timeOutDelay = setTimeout(() => {
        this.$store.commit(`tabs/SET_${this.nameComponent.toUpperCase()}`, { outlineGroup: { ...this.opOutlineGroup } });
      }, this.timeDelay);
    },
    'opResample.autoResample'(val) {
      if (!val) {
        this.opMethodsResample = null;
      } else {
        this.opMethodsResample = this.resample.methods;
      }
    },
    'opResample.value'(val) {
      if (val == 1 || val == '1') {
        this.opMethodsResample = 'none';
      }
    },
    'opResample.max'(val) {
      if (Number(this.opResample.value) > Number(val)) {
        this.opResample.value = val;
      }
    },
    resample: {
      deep: true,
      handler() {
        if (this.opResample.autoResample) {
          this.opMethodsResample = this.resample.methods;
        }
        if (this.selectTimeseries) {
          switch (this.selectTimeseries) {
            case 'xy1': {
              this.opResample.max = this.resample.opResampleTemp[0].max;
              break;
            }
            case 'xy2': {
              this.opResample.max = this.resample.opResampleTemp[1].max;
              break;
            }
            case 'xy3': {
              this.opResample.max = this.resample.opResampleTemp[2].max;
              break;
            }
            case 'xy4': {
              this.opResample.max = this.resample.opResampleTemp[3].max;
              break;
            }
            default: {
              this.opResample.max = this.resample.opResampleTemp[0].max;
              break;
            }
          }
        }
      },
    },
    optionModalFlag(isShow) {
      if (isShow == true) {
        if (this.selectedLayout && this.selectedLayout != '') {
          this.moveCountRadioGroupLayout++;
        }
        if (this.typeSelected && this.typeSelected != '') {
          this.moveCountRadioGroupChartType++;
        }
        if (this.scaleOption && this.scaleOption != '') {
          this.moveCountRadioGroupScale++;
        }
        if (this.opFillData && this.opFillData != '') {
          this.moveCountRadioGroupFillData++;
        }
        if (this.resampleSeasonal && this.resampleSeasonal != '') {
          this.moveCountResampleSeasonal++;
        }
        this.moveCountPalettePicker++;
        if (this.nameComponent == 'timeseries') {
          if (this.selectTimeseries) {
            switch (this.selectTimeseries) {
              case 'xy1': {
                this.opResample = this.$store.state.tabs[this.nameComponent].arrayDatasourceSelect[0].resample;
                break;
              }
              case 'xy2': {
                this.opResample = this.$store.state.tabs[this.nameComponent].arrayDatasourceSelect[1].resample;
                break;
              }
              case 'xy3': {
                this.opResample = this.$store.state.tabs[this.nameComponent].arrayDatasourceSelect[2].resample;
                break;
              }
              case 'xy4': {
                this.opResample = this.$store.state.tabs[this.nameComponent].arrayDatasourceSelect[3].resample;
                break;
              }
              default: {
                this.opResample = this.$store.state.tabs[this.nameComponent].arrayDatasourceSelect[0].resample;
                break;
              }
            }
          }
        } else {
          if (this.$store.state.tabs[this.nameComponent].resample) {
            this.opResample = this.$store.state.tabs[this.nameComponent].resample;
          }
        }
        if (this.nameComponent == 'lines') {
          if (this.$store.state.tabs[this.nameComponent].symbolSelected && this.symbols) {
            const symbolSelected = this.$store.state.tabs[this.nameComponent].symbolSelected;
            this.symbolSelected = [...symbolSelected.map((sb) => this.symbols.indexOf(sb))];
            this.getDataSymbolsSelected(this.symbolSelected);
            this.symbolSelectedChart(this.$refs.refChartShapeSelected, this.dataSymbolsSelected);
            this.resetSymbolChart(this.$refs.refChartShapePlotly, this.dataSymbols, this.symbolSelected);
          }
        }
      }
    },
    theme() {
      const color = this.isDark ? '#d0d2d6' : '#444';
      this.$store.commit(`tabs/SET_${this.nameComponent.toUpperCase()}`, { chartTextColor: color });
    },
  },
  methods: {
    keypressInput() {
      clearTimeout(this.timeOutDelay);
      this.timeOutDelay = setTimeout(() => {
        this.emitSizeWidth();
      }, this.timeDelay);
    },
    emitSizeWidth() {
      let sizeWidth = { ...this.opSizeWidth };
      if (this.selectTimeseries) {
        let arrayDatasourceSelect = [...this.$store.state.tabs[this.nameComponent].arrayDatasourceSelect];
        switch (this.selectTimeseries) {
          case 'xy1': {
            arrayDatasourceSelect[0].sizeWidth = sizeWidth;
            break;
          }
          case 'xy2': {
            arrayDatasourceSelect[1].sizeWidth = sizeWidth;
            break;
          }
          case 'xy3': {
            arrayDatasourceSelect[2].sizeWidth = sizeWidth;
            break;
          }
          case 'xy4': {
            arrayDatasourceSelect[3].sizeWidth = sizeWidth;
            break;
          }
        }
        this.$store.commit(`tabs/SET_${this.nameComponent.toUpperCase()}`, { arrayDatasourceSelect });
      }
      this.$store.commit(`tabs/SET_${this.nameComponent.toUpperCase()}`, { sizeWidth });
    },
    keypressResample() {
      clearTimeout(this.timeOutDelay);
      this.timeOutDelay = setTimeout(() => {
        this.emitResample();
      }, this.timeDelay);
    },
    emitResample() {
      let resample = { ...this.opResample };
      if (this.selectTimeseries) {
        let arrayDatasourceSelect = [...this.$store.state.tabs[this.nameComponent].arrayDatasourceSelect];
        switch (this.selectTimeseries) {
          case 'xy1': {
            arrayDatasourceSelect[0].resample = resample;
            break;
          }
          case 'xy2': {
            arrayDatasourceSelect[1].resample = resample;
            break;
          }
          case 'xy3': {
            arrayDatasourceSelect[2].resample = resample;
            break;
          }
          case 'xy4': {
            arrayDatasourceSelect[3].resample = resample;
            break;
          }
          default: {
            arrayDatasourceSelect.resample = resample;
            break;
          }
        }
        this.$store.commit(`tabs/SET_${this.nameComponent.toUpperCase()}`, { arrayDatasourceSelect });
      }
      this.$store.commit(`tabs/SET_${this.nameComponent.toUpperCase()}`, { resample });
    },
    getOption() {
      this.typeSelected = this.$store.state.tabs[this.nameComponent].typeSelected;
      this.opSizeWidth = { ...this.$store.state.tabs[this.nameComponent].sizeWidth };
      this.opOutlineGroup = { ...this.$store.state.tabs[this.nameComponent].outlineGroup };
      let symbolSelected = this.$store.state.tabs[this.nameComponent].symbolSelected;
      this.symbolSelected = symbolSelected ? [...symbolSelected] : [];
      if (this.opResample.autoResample && this.resample) {
        this.opMethodsResample = this.resample.methods;
      }
    },
    openCollapse(nameRefs) {
      this.$nextTick(() => {
        this.$refs[nameRefs].moveIndicator();
      });
    },
    getSymbols() {
      var isOpen = (s) => s.includes('-open');
      var isDot = (s) => s.includes('-dot');
      var isBase = (s) => !isOpen(s) && !isDot(s);
      var symbolList = Plotly.PlotSchema.get().traces.scatter.attributes.marker.symbol.values.filter((s) => typeof s === 'string');
      this.symbols = symbolList.filter(isBase);
      this.symbolSelected = [...this.symbolSelected.map((sb) => this.symbols.indexOf(sb))];
    },
    getDataSymbols(symbols) {
      var rowLen = this.rowLenSymbols;
      let xTrace = 0;
      let yTrace = -10;
      this.dataSymbols = [];
      for (let i = 0; i < symbols.length; i++) {
        // var s = symbols[i] + opt
        xTrace = i % rowLen;
        if (xTrace == 0) {
          yTrace += 10;
        }
        this.dataSymbols.push({
          x: [xTrace],
          y: [yTrace],
          mode: 'markers',
          type: 'scatter',
          marker: {
            color: DEFAULT_COLOR_HIDEN_SYMBOL,
            size: 16,
            symbol: symbols[i],
            line: {
              color: DEFAULT_COLOR_HIDEN_SYMBOL,
              width: 1,
            },
          },
          opacity: DEFAULT_OPACITY_HIDEN_SYMBOL,
          showlegend: false,
          hovertemplate: `${symbols[i]}<extra></extra>`,
        });
      }
    },
    mergeSymbolsSelectedInDataSymbols(symbolSelected) {
      if (symbolSelected.length > 0 && this.symbols.length > 0) {
        for (let i = 0; i < symbolSelected.length; i++) {
          this.dataSymbols[symbolSelected[i]].opacity = 1;
          this.dataSymbols[symbolSelected[i]].marker.color = DEFAULT_COLOR_SHOW_SYMBOL;
        }
      }
    },
    getDataSymbolsSelected(symbolsSected) {
      var rowLen = this.rowLenSymbols;
      let xTrace = 0;
      let yTrace = -1;
      this.dataSymbolsSelected = [];
      for (let i = 0; i < symbolsSected.length; i++) {
        // var s = symbols[i] + opt
        xTrace = i % rowLen;
        if (xTrace == 0) {
          yTrace += 1;
        }
        let symbol = this.symbols[symbolsSected[i]];
        this.dataSymbolsSelected.push({
          x: [xTrace],
          y: [yTrace],
          mode: 'markers',
          type: 'scatter',
          marker: {
            color: DEFAULT_COLOR_SHOW_SYMBOL,
            size: 16,
            symbol,
          },
          opacity: 1,
          hovertemplate: `${symbol}<extra></extra>`,
        });
      }
    },
    symbolChart(chart, data) {
      let layout = {
        width: 300,
        height: 800,
      };
      let _layout = _.merge(LAYOUT, layout);
      if (chart[0]) {
        chart[0] = '';
      }
      Plotly.newPlot(chart, data, _layout, CONFIG);
    },
    resetSymbolChart(chart, data, markerSymbol) {
      data.forEach((trace, id) => {
        if (markerSymbol.indexOf(id) > -1) {
          data[id].opacity = 1;
          data[id].marker.color = DEFAULT_COLOR_SHOW_SYMBOL;
        } else {
          data[id].opacity = DEFAULT_OPACITY_HIDEN_SYMBOL;
          data[id].marker.color = DEFAULT_COLOR_HIDEN_SYMBOL;
        }
      });
      let layout = {
        width: 300,
        height: 800,
      };
      let _layout = _.merge(LAYOUT, layout);
      Plotly.redraw(chart, data, _layout, CONFIG);
    },
    symbolSelectedChart(chart, data) {
      let heightLen = Math.ceil(data.length / this.rowLenSymbols);
      let height = heightLen <= 1 ? 30 : 30 + heightLen * 20;
      let layout = {
        width: 300,
        height: height,
        yaxis: { range: [-1, 2], autorange: 'reversed' },
        xaxis: { range: [-1, this.rowLenSymbols], autorange: false },
      };
      let _layout = _.merge(LAYOUT, layout);
      if (chart[0]) {
        chart[0] = '';
      }
      Plotly.newPlot(chart, data, _layout, CONFIG);
    },
    saveMarkerSymbol() {
      let markerSymbol = this.symbolSelected.map((id) => this.symbols[id]);
      this.$store.commit(`tabs/SET_${this.nameComponent.toUpperCase()}`, { symbolSelected: markerSymbol });
    },
    resetMarkerSymbol() {
      let markerSymbol = [...this.markerSymbolDefault];
      this.symbolSelected = markerSymbol;
      this.saveMarkerSymbol();
      this.resetSymbolChart(this.$refs.refChartShapePlotly, this.dataSymbols, markerSymbol);
    },
    clickOutsideDiv(div) {
      const option_group = div;
      document.addEventListener('mousedown', (event) => {
        if (!option_group.contains(event.target)) {
          this.optionModalFlag = false;
        }
      });
    },
    getRealtimeDataRange(data) {
      if (data && data.length > 1) {
        this.opSizeWidth.range = data;
      }
    },
    getDataRange(data) {
      if (data && data.length > 1) {
        this.opSizeWidth.range = data;
        this.emitSizeWidth();
      }
    },
    saveOutlineGroup() {
      this.$store.commit(`tabs/SET_${this.nameComponent.toUpperCase()}`, { outlineGroup: { ...this.opOutlineGroup } });
    },
  },
};
</script>

<style scoped lang="scss">
.option {
  position: absolute;
  top: calc(100% + 10px);
  left: 50%;
  transform: translateX(-50%);
  // transform: translateX(-15px);
  width: 700px;
  border: solid 10px rgba(57, 123, 255, 0.12);
  box-shadow: rgba(0, 0, 0, 0.25) 0px 25px 50px -12px;
  border-radius: 5px;
  transition: all linear 0.2s;
  color: rgb(128, 128, 128);

  &-content {
    border-radius: 5px;
    padding: 5px;
    &-size {
      &-items {
        label {
          padding: 1px !important;
        }
      }
      &-ip {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border: solid 0.5px #7b7b7b4a;
        border-radius: 5px;
        padding: 0px 6px;
        input {
          padding: 0 2px;
          max-width: 202px;
          border: none;
          color: #5e5873;
          background-color: transparent;
          &:focus {
            outline: none;
            box-shadow: none;
          }
        }
        .ip_text {
          flex: 1;
          max-width: 1.8rem;
        }
        .ip_range {
          margin-left: 6px;
          flex: 1;
        }
      }
      &-auto {
        margin-left: 2px !important;
        font-size: 14px;
      }
    }
    .btn-resets-size {
      button {
        padding: 8px;
        font-size: 10px;
        margin-left: 3px;
      }
    }
    .option-chart-items_split2 {
      display: flex;
      flex-wrap: wrap;
      margin-left: -3px;
      margin-right: -3px;
      .split2-items {
        width: calc(50% - 7px);
        margin: 3px;
        padding: 5px 5px;
        border-radius: 5px;
        // background-color: rgba(57, 123, 255, 0.12);
      }
      .full-items {
        width: 100%;
        margin: 3px;
        padding: 5px 5px;
        border-radius: 5px;
        // background-color: rgba(57, 123, 255, 0.12);
      }
    }
    .option-chart-items_split3 {
      display: flex;
      flex-wrap: wrap;
      padding: 5px 5px;
      border-radius: 5px;
      // background-color: rgba(57, 123, 255, 0.12);
      margin-top: 3px;
      .split3-items {
        width: calc(33.333333% - 7px);
        margin: 3px;
      }
      .full-items {
        width: 100%;
        margin: 3px;
        padding: 5px 5px;
        border-radius: 5px;
        // background-color: rgba(57, 123, 255, 0.12);
      }
    }
    .full-items {
      padding: 5px 5px;
      border-radius: 5px;
      // background-color: rgba(57, 123, 255, 0.12);
    }
  }
  &-title {
    width: 100% !important;
    padding: 0 !important;
  }

  .plot-container {
    width: 100% !important;
  }

  .chart-shape-plotly {
    width: 100%;
    height: 260px;
    overflow: hidden scroll !important;

    &::-webkit-scrollbar {
      width: 4px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #ccc;
    }
  }

  #shape-selected {
    border: solid 1px #787c825c;
    border-radius: 4px;
    max-height: 245px;
    height: 36px;
    overflow-y: scroll;

    &::-webkit-scrollbar {
      width: 4px;
      height: 4px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #ccc;
    }
  }
  .items-no-group {
    padding: 0.45rem 0;
  }
  &-group {
    width: fit-content;
    .option-modal {
      border: none;
      background: transparent;
      .option {
        visibility: hidden;
        opacity: 0;
        top: calc(100% - 20px);
      }
      &:focus {
        border: none;
        outline: none;
        .option {
          visibility: visible;
          opacity: 1;
          top: calc(100% + 10px);
        }
      }
    }
  }
  &-container {
    border: 1px solid rgba(115, 103, 240, 0.24);
    border-radius: 0.357rem;
    padding: 4px;
    display: flex;
    position: relative;
    user-select: none;
    width: 50px;
    label {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 32px;
      margin: 0;
      padding: 0 12px;
      cursor: pointer;
      border-radius: 0.357rem;
      white-space: nowrap;
      width: fit-content;
      color: rgb(128, 128, 128);
      font-weight: 500;
    }
  }
  .layer-collapse-item {
    font-size: 12px;
    font-weight: 500;
    padding-right: 10px;
    padding-bottom: 5px;
    .select-tilte {
      p {
        line-height: 22px;
        text-transform: uppercase;
        min-width: 82px;
        display: inline-block;
        font-size: 14px;
      }
      & > svg {
        margin-left: 1px;
        margin-right: 11px;
        min-width: 14px;
        color: #397bff;
      }
    }
  }
  .color-title {
    svg {
      width: 14px;
      height: 14px;
      margin-left: 1px;
      margin-right: 11px;
      min-width: 14px;
    }
  }
  .size-width {
    &-range {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 0.857rem;
      border: solid 0.5px #7b7b7b4a;
      border-radius: 5px;
      padding: 8px 6px;
      margin-top: 4px;
      &-label {
        width: 60px;
      }
      &-content {
        flex: 1;
        max-width: 250px;
      }
    }
  }
  .option-color {
    &-group {
      display: flex;
      align-items: center;
      justify-content: space-between;
      &-check {
        display: flex;
        align-items: center;
      }
      &-row {
        &-item {
          margin-top: 8px;
          flex: 1;
          display: flex;
          flex-wrap: nowrap;
          align-items: center;
          &-icon {
            margin-right: 10px;
          }
          &:not(:first-child) {
            margin-left: 10px;
          }
          input {
            width: 100%;
            padding: 3px 5px;
            color: #5e5873;
            &:focus {
              outline: none;
              border-color: #719ecebd;
            }
          }
          &-num {
            display: flex;
            align-items: center;
            border: solid 0.5px #7b7b7b4a;
            border-radius: 4px;
            flex: 1;
            input {
              width: 40px;
              border: none;
              &:focus {
                outline: none;
              }
            }
            input.ip_range {
              flex: 1;
            }
          }
        }
      }
    }
  }
}
.ml-10 {
  margin-left: 10px;
}

.show-option {
  visibility: visible;
  opacity: 1;
  top: calc(100% + 1rem);
  z-index: 2;
}
.hidden-option {
  visibility: hidden;
  opacity: 0;
  top: calc(100% - 20px);
  z-index: -1;
}
.option-group {
  .dis-opt {
    opacity: 0.6;
    cursor: not-allowed !important;
    * {
      pointer-events: none !important;
    }
  }
}
.label-form-group {
  padding-bottom: calc(0.438rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.45;
  font-weight: 600;
  display: flex;
  align-items: center;
  text-transform: uppercase;
}
</style>

<style lang="scss">
.option-type-layout svg path {
  stroke: rgb(128, 128, 128) !important;
  stroke-width: 2 !important;
  fill: rgb(128, 128, 128) !important;
}

.chart-shape-plotly {
  g {
    cursor: pointer !important;
  }

  .plot-container .main-svg {
    top: -25px !important;
  }
}

.chart-shape-selected {
  g {
    cursor: pointer !important;
  }

  .plot-container .main-svg {
    top: 5px !important;
  }
}
.option {
  &-content {
    .card-header {
      padding: 0.45rem 0 !important;
    }
    .card-body {
      padding: 0 0 0.5rem 0 !important;
    }
  }
  .multi-range {
    display: flex;
    justify-content: flex-end;
  }
}
.shape-seleted-label {
  padding-left: 0 !important;
}
.option-chart-title {
  text-transform: uppercase;
}
.dark-layout {
  .option-modal {
    color: rgb(128, 128, 128);
  }
  .option-content-size {
    input {
      color: rgb(128, 128, 128);
    }
  }
  .option-chart-title {
    background: #283046;
  }
  .resample-type_fill-data {
    label {
      color: rgb(128, 128, 128);
    }
  }
}
.resample {
  &-label {
    margin-right: 12px !important;
  }
  &-input {
    display: flex;
    align-items: center;
    border: solid 0.5px #7b7b7b4a;
    border-radius: 0.357rem;
    padding: 0 4px;
    input {
      padding: 0 2px;
      border: none;
      color: #5e5873;
      background-color: transparent;
      &:focus {
        outline: none;
        box-shadow: none;
      }
      &:first-child {
        max-width: 38px;
      }
    }
  }
  &-autoGroup {
    flex: 1;
  }
  &-type {
    flex: 1;
    padding-right: 10px;
    margin-right: 7px;
  }
}
.trello-icon {
  transform: rotate(-90deg);
}
.fast-forward-icon {
  transform: rotate(90deg);
}
.color-icon {
  transform: rotateY(180deg);
}

.option-chart {
  &-title {
    font-size: 12px;
    font-weight: 600;
    margin-bottom: 5px;
    position: absolute;
    top: 0;
    display: inline-block;
    padding: 0 8px 0 0px;
    transform: translateY(-50%);
    opacity: 0.7;
  }
  &_style {
    padding: 5px;
    padding-top: 15px;
    margin-top: 10px;
    border-radius: 10px;
    position: relative;
  }
  &_data {
    padding: 5px;
    padding-top: 15px;
    margin-top: 10px;
    border-radius: 10px;
    position: relative;
    margin-top: 1.5rem;
    .full-items {
      margin: 3px 0;
    }
  }
}
.palette-picker-container {
  & > .form-control-sm {
    height: 40px !important;
  }
}
.palette-picker-container {
  margin-top: 5px;
}
.methods-resample {
  opacity: 0.7;
  &-active {
    color: #397bff;
  }
}
.opt-chart-type,
.opt-layout,
.opt-chart-scale,
.opt-fill-data {
  .radio-group {
    margin-top: 5px;
  }
}
.opt-font-chart {
  width: 100%;
  // display: flex;
  // align-items: center;
  // justify-content: space-between;
}
.resample-type_fill-data {
  display: flex;
  align-items: center;
  border: solid 0.5px #7b7b7b4a;
  border-radius: 0.357rem;
  padding: 0 4px;
}
.resample-autoGroup-methods {
  margin-top: 5px;
  margin-bottom: 5px;
}

// timeseries card
.timeseries-card {
  .opt-layout {
    border-radius: 5px 5px 0 0 !important;
  }
  .opt-color {
    margin-top: -3px !important;
    padding-top: 3px !important;
    border-radius: 0 0 5px 5px !important;
  }
  .opt-font-chart {
    // background: red;
  }
}

.line-card {
  .opt-size-width {
    margin-top: -300px !important;
    order: 3;
  }
  .opt-chart-type {
    order: 1;
    height: 83px !important;
    border-radius: 5px 5px 0 0 !important;
  }
  .opt-color {
    transform: translateX(calc(-100% - 5px));
    margin-top: -195px !important;
    order: 4;
    z-index: 99999;
  }
  .opt-shape {
    order: 2;
    height: 380px !important;
  }
  .opt-size-width {
    height: 98px !important;
    border-radius: 0 0 5px 5px !important;
  }
  .opt-chart-scale {
    width: 100% !important;
  }
  .option-chart-items_split3 {
    flex-wrap: nowrap !important;
  }
  .option {
    .layer-collapse-item {
      .select-tilte {
        p {
          min-width: 62px !important;
        }
      }
    }
  }
}
.label-font-chart {
  margin-bottom: 5px;
}
.scatter-card {
  .opt-chart-scale {
    width: 100% !important;
  }
  .opt-size-width {
    margin-right: 3.8px;
  }
}
.boxplot-card {
  .option-chart-items_split2 {
    // background-color: rgba(57, 123, 255, 0.12) !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
    border-radius: 5px;
  }
  .opt-color {
    background: transparent !important;
  }
  .opt-chart-scale {
    background: transparent !important;
  }
  .option-chart-items_split3 {
    margin-top: 5px !important;
  }
}
.histogram-card {
  .opt-chart-scale {
    width: 100% !important;
  }
  .option-chart-items_split3 {
    flex-wrap: nowrap !important;
  }
  .option {
    .layer-collapse-item {
      .select-tilte {
        p {
          min-width: 62px !important;
        }
      }
    }
  }
}
.option-color-group-label {
  font-size: 14px;
}
</style>
