<template>
  <b-card no-body class="seasonal-analytics-chart-card">
    <b-card-body>
      <div class="seasonal-analytics-chart" id="seasonal-analytics-plotly-chart" v-show="showChart" ref="chart"></div>
      <div class="seasonal-analytics-chart" v-show="!showChart">
        <EmptyChart :description="$t('tips_seasonal_analytics')" />
      </div>
      <ChartYearText ref="refChartYearText" v-if="showChart && showYear && $parent.$parent.yearText" :text="$parent.$parent.yearText" />
    </b-card-body>
  </b-card>
</template>

<script>
const _ = require('lodash')
import Plotly from 'plotly.js-dist'
import DownloadMixin from '@/mixins/DownloadMixin'
import { CustomLayout } from '@/mixins/ThemeMixin.js'
import EmptyChart from '../../common/EmptyChart.vue'
import ChartYearText from '../../common/ChartYearText.vue'
import i18n from '@/libs/i18n'
import { locale } from '@/scripts/plotly/locale.js'
import { OPTION_PLOTLY_PALETTE } from '@/constants/colors'

const CONFIG = {
  displaylogo: false,
  responsive: true,
  editable: true,
  modeBarButtonsToRemove: ['toImage'],
}

const MONTHS_MAP = {
  January: '1月',
  February: '2月',
  March: '3月',
  April: '4月',
  May: '5月',
  June: '6月',
  July: '7月',
  August: '8月',
  September: '9月',
  October: '10月',
  November: '11月',
  December: '12月',
}

export default {
  props: ['chart', 'showYear', 'nameComponent'],
  mixins: [DownloadMixin, CustomLayout],
  components: { EmptyChart, ChartYearText, locale },
  data() {
    return {
      layout: null,
      funcEventPlotlyRelayout: null,
    }
  },
  mounted() {
    'undefined' == typeof Plotly ? ((window.PlotlyLocales = window.PlotlyLocales || []), window.PlotlyLocales.push(locale)) : Plotly.register(locale)
    this.updateChart()

    // listen to div resize => chart resize
    new ResizeObserver(() => {
      if (this.$route.name == 'seasonal-analytics' && this.$refs.chart && this.$refs.chart.style && ((this.$refs.chart.style.display && this.$refs.chart.style.display !== 'none') || !this.$refs.chart.style.display)) {
        Plotly.Plots.resize(this.$refs.chart)
      }
    }).observe(this.$refs.chart)
  },
  computed: {
    showChart() {
      try {
        return Object.keys(this.chart || {}).length
      } catch (error) {}
    },
    locale() {
      return i18n.locale
    },
    layoutChart() {
      return {
        showlegend: this.showLegend,
        xaxis1: this.resampleSeasonal === 'origin' ? { automargin: true, anchor: 'y1', type: 'category', tickmode: 'array' } : { automargin: true, anchor: 'y1' },
        yaxis1: { automargin: true, domain: [0.7, 1], anchor: 'x1', title: this.$i18n.t('by_year') },

        xaxis2: { automargin: true, anchor: 'y2', type: 'category' },
        yaxis2: { automargin: true, domain: [0.35, 0.65], anchor: 'x2', title: this.$i18n.t('by_month') },

        xaxis3: { automargin: true, anchor: 'y3' },
        yaxis3: { automargin: true, domain: [0, 0.3], anchor: 'x3', title: this.$i18n.t('by_month_boxplot') },
      }
    },
    showLegend() {
      return this.$store.state.tabs['seasonal_analytics'].legend
    },
    editable() {
      return this.$store.state.tabs['seasonal_analytics'].editable
    },
    typeSelected() {
      return this.$store.state.tabs['seasonal_analytics'].typeSelected
    },
    colorsCycle() {
      return this.$store.state.tabs['seasonal_analytics'].colorsCycle
    },
    colorsUnit() {
      return this.$store.state.tabs['seasonal_analytics'].colorsUnit
    },
    colorsUnitBoxplot() {
      return this.$store.state.tabs['seasonal_analytics'].colorsUnitBoxplot
    },
    sizeWidth() {
      return this.$store.state.tabs['seasonal_analytics'].sizeWidth
    },
    resampleSeasonal() {
      return this.$store.state.tabs['seasonal_analytics'].resampleSeasonal
    },
    chartFontFamily() {
      return this.$store.state.tabs[this.nameComponent].chartFontFamily
    },
    chartTextSize() {
      return this.$store.state.tabs[this.nameComponent].chartTextSize
    },
    chartTextColor() {
      return this.$store.state.tabs[this.nameComponent].chartTextColor
    },
    chartFont() {
      let font = {}
      try {
        if (this.chartFontFamily) {
          font.family = this.chartFontFamily
        }
        if (this.chartTextSize) {
          font.size = this.chartTextSize
        }
        if (this.chartTextColor) {
          font.color = this.chartTextColor
        }
      } catch {}
      return font
    },
    chartLayout() {
      return this.$store.state.tabs.seasonal_analytics.chartLayout
    },
  },
  watch: {
    chart: {
      deep: true,
      handler() {
        this.updateChart(true)
      },
    },
    '$i18n.locale': function () {
      if (this.$route.name === 'seasonal-analytics') {
        this.updateChart()
      }
    },
    locale() {
      this.updateChart()
    },
    showLegend() {
      this.updateChart(true)
    },
    editable() {
      this.updateChart()
    },
    typeSelected() {
      this.updateChart(true)
    },
    colorsCycle() {
      this.updateChart(true)
    },
    colorsUnit() {
      this.updateChart(true)
    },
    colorsUnitBoxplot() {
      this.updateChart(true)
    },
    sizeWidth: {
      deep: true,
      handler() {
        this.updateChart(true)
      },
    },
    chartFont: {
      deep: true,
      handler() {
        if (this.chartFont) {
          this.updateChartLayout()
        }
      },
    },
    $route: {
      deep: true,
      handler() {
        try {
          if (this.$route.name == 'seasonal-analytics') {
            this.eventPlotlyLayout()
          }
        } catch {}
      },
    },
  },
  methods: {
    eventPlotlyLayout() {
      const self = this
      try {
        if (self.funcEventPlotlyRelayout) {
          clearTimeout(self.funcEventPlotlyRelayout)
        }
        self.funcEventPlotlyRelayout = setTimeout(() => {
          let plotlyElement = document.getElementById('seasonal-analytics-plotly-chart')
          if (plotlyElement) {
            plotlyElement.on('plotly_relayout', (data) => {
              try {
                let layoutOrigin = plotlyElement.layout
                let layoutTemp = {
                  xaxis: {},
                  xaxis2: {},
                  xaxis3: {},
                  xaxis4: {},
                  yaxis: {},
                  yaxis2: {},
                  yaxis3: {},
                  yaxis4: {},
                  yaxis5: {},
                  yaxis6: {},
                  yaxis7: {},
                  yaxis8: {},
                }
                if (layoutOrigin.title) {
                  layoutTemp.title = layoutOrigin.title
                }
                if (layoutOrigin.xaxis && layoutOrigin.xaxis.title) {
                  layoutTemp.xaxis.title = layoutOrigin.xaxis.title
                }
                if (layoutOrigin.xaxis2 && layoutOrigin.xaxis2.title) {
                  layoutTemp.xaxis2.title = layoutOrigin.xaxis2.title
                }
                if (layoutOrigin.xaxis3 && layoutOrigin.xaxis3.title) {
                  layoutTemp.xaxis3.title = layoutOrigin.xaxis3.title
                }
                if (layoutOrigin.xaxis4 && layoutOrigin.xaxis4.title) {
                  layoutTemp.xaxis4.title = layoutOrigin.xaxis4.title
                }
                if (layoutOrigin.yaxis && layoutOrigin.yaxis.title) {
                  layoutTemp.yaxis.title = layoutOrigin.yaxis.title
                }
                if (layoutOrigin.yaxis2 && layoutOrigin.yaxis2.title) {
                  layoutTemp.yaxis2.title = layoutOrigin.yaxis2.title
                }
                if (layoutOrigin.yaxis3 && layoutOrigin.yaxis3.title) {
                  layoutTemp.yaxis3.title = layoutOrigin.yaxis3.title
                }
                if (layoutOrigin.yaxis4 && layoutOrigin.yaxis4.title) {
                  layoutTemp.yaxis4.title = layoutOrigin.yaxis4.title
                }
                if (layoutOrigin.yaxis5 && layoutOrigin.yaxis5.title) {
                  layoutTemp.yaxis5.title = layoutOrigin.yaxis5.title
                }
                if (layoutOrigin.yaxis6 && layoutOrigin.yaxis6.title) {
                  layoutTemp.yaxis6.title = layoutOrigin.yaxis6.title
                }
                if (layoutOrigin.yaxis7 && layoutOrigin.yaxis7.title) {
                  layoutTemp.yaxis7.title = layoutOrigin.yaxis7.title
                }
                if (layoutOrigin.yaxis8 && layoutOrigin.yaxis8.title) {
                  layoutTemp.yaxis8.title = layoutOrigin.yaxis8.title
                }
                if (self.$route && self.$route.name == 'seasonal-analytics') {
                  self.$store.commit(`tabs/SET_SEASONAL_ANALYTICS`, { chartLayout: layoutTemp })
                }
              } catch {}
            })
          }
        }, 1000)
      } catch {}
    },
    updateChart(isUpdate = false) {
      let _config = CONFIG
      _config.locale = i18n.locale
      _config = _.merge(_config, { editable: this.editable })
      let layoutTemp = {}
      try {
        if (this.chartFont) {
          layoutTemp = _.merge(layoutTemp, { font: this.chartFont })
        }
        if (this.chartLayout) {
          layoutTemp = _.merge(layoutTemp, this.chartLayout)
        }
      } catch {}
      if (isUpdate) {
        if (this.$refs.chart) {
          this.layout = _.merge({}, this.$refs.chart.layout, this.themeLayout, this.layoutChart, this.chart.layout, layoutTemp)
          delete this.layout.xaxis
          // delete this.layout.yaxis;
          Plotly.react(this.$refs.chart, this.getData(), this.layout, _config)
        }
      } else {
        this.layout = _.merge({}, this.themeLayout, this.layoutChart, this.chart.layout, layoutTemp)

        delete this.layout.xaxis
        delete this.layout.yaxis
        if (this.$refs.chart && this.$refs.chart.layout) {
          if (this.$refs.chart.layout.title) {
            this.layout.title = this.$refs.chart.layout.title
          }
        }
        Plotly.newPlot(this.$refs.chart, this.getData(), this.layout, _config)
        this.eventPlotlyLayout()
      }
    },
    convertMonthEnglishToJapanese(months) {
      let arrConver = _.cloneDeep(months)
      for (let i = 0; i < arrConver.length; i++) {
        arrConver[i] = MONTHS_MAP[arrConver[i]] ? MONTHS_MAP[arrConver[i]] : arrConver[i]
      }
      return arrConver
    },
    getData() {
      let lineWidth = null
      let markerSize = null
      if (this.sizeWidth.default == true) {
        lineWidth = this.sizeWidth.width
        markerSize = this.sizeWidth.size
      }
      let chartData = []
      let traceCountYear = 0
      let traceCountMonth = 0
      let traceCountMonthBoxplot = 0
      const colorsCycle = this.colorsCycle || OPTION_PLOTLY_PALETTE.seasonalByYear
      const colorsUnit = this.colorsUnit || OPTION_PLOTLY_PALETTE.seasonalByMonth
      const colorsUnitBoxplot = this.colorsUnitBoxplot || OPTION_PLOTLY_PALETTE.seasonalByMonth

      let resultSeasonalData = this.chart.data || []
      if (resultSeasonalData.length === 0) return []

      for (let j = 0; j < resultSeasonalData.length; j++) {
        if (resultSeasonalData[j].nameChart === 'timeseriesYear') {
          let dataSeaonal = resultSeasonalData[j].data
          let maxArray = _.maxBy(Object.values(dataSeaonal), (arr) => arr.data.length)

          // Tạo mảng chứa các tháng
          var months = [...new Set(maxArray.date.map((date) => date.split('-')[0]))]

          // Tạo mảng chứa ngày cuối cùng của mỗi tháng
          var lastDayOfMonths = []
          for (var i = 0; i < months.length; i++) {
            var monthData = maxArray.date.filter((date) => date.startsWith(months[i]))
            lastDayOfMonths.push(monthData[0])
          }

          this.layout.xaxis1.tickvals = lastDayOfMonths
          this.layout.xaxis1.ticktext = this.locale === 'en' ? months : this.convertMonthEnglishToJapanese(months) || []

          chartData.push({
            name: 'tracesHiddenYear',
            x: this.locale === 'en' ? maxArray.date || [] : this.convertMonthEnglishToJapanese(maxArray.date) || [],
            y: maxArray.data,
            mode: this.typeSelected,
            xaxis: 'x1',
            yaxis: 'y1',
            type: 'scatter',
            connectgaps: true,
            line: { color: 'transparent' },
            showlegend: false,
          })

          for (const year in dataSeaonal) {
            let color = colorsCycle[traceCountYear % colorsCycle.length]
            chartData.push({
              name: year,
              x: this.locale === 'en' ? dataSeaonal[year]['date'] || [] : this.convertMonthEnglishToJapanese(dataSeaonal[year]['date']) || [],
              y: dataSeaonal[year]['data'] || [],
              mode: this.typeSelected,
              xaxis: 'x1',
              yaxis: 'y1',
              type: 'scatter',
              connectgaps: true,
              line: { width: lineWidth, color: color },
              marker: { size: markerSize, color: color },
            })
            traceCountYear++
          }
        }

        if (resultSeasonalData[j].nameChart === 'timeseriesMonth') {
          let dataSeaonal = resultSeasonalData[j].data
          for (const month in dataSeaonal) {
            let color = colorsUnit[traceCountMonth % colorsUnit.length]
            chartData.push({
              name: this.locale === 'en' ? month : MONTHS_MAP[month],
              x: dataSeaonal[month]['date'] || [],
              y: dataSeaonal[month]['data'] || [],
              mode: this.typeSelected,
              xaxis: 'x2',
              yaxis: 'y2',
              type: 'scatter',
              connectgaps: false,
              line: { width: lineWidth, color: color },
              marker: { size: markerSize, color: color },
            })
            traceCountMonth++
          }
        }

        if (resultSeasonalData[j].nameChart === 'dataByBoxplot') {
          let dataSeaonal = resultSeasonalData[j].data

          const sortedData = {}
          const monthOrder = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']

          monthOrder.forEach((month) => {
            if (dataSeaonal[month]) sortedData[month] = dataSeaonal[month]
          })

          for (const month in sortedData) {
            let color = colorsUnitBoxplot[traceCountMonthBoxplot % colorsUnitBoxplot.length]
            chartData.push({
              y: sortedData[month]['data'] || [],
              name: this.locale === 'en' ? month : MONTHS_MAP[month],
              boxpoints: 'all',
              jitter: 0.3,
              pointpos: -1.8,
              type: 'box',
              xaxis: 'x3',
              yaxis: 'y3',
              marker: { size: markerSize, color: color },
            })
            traceCountMonthBoxplot++
          }
        }
      }

      return chartData
    },
    updateChartLayout() {
      // setTimeout(() => {
      try {
        let layout = this.$refs.chart.layout
        let layoutTemp = {}
        try {
          if (this.chartFont) {
            layoutTemp = _.merge(layoutTemp, { font: this.chartFont })
          }
          if (this.chartLayout) {
            layoutTemp = _.merge(layoutTemp, this.chartLayout)
          }
        } catch {}
        layout = _.merge(layout, layoutTemp)
        Plotly.relayout(this.$refs.chart, layout)
        // Plotly.react(this.$refs.chart, this.$refs.chart.data, layout);
      } catch {}
      // }, 300);
    },
  },
}
</script>

<style scoped>
.seasonal-analytics-chart-card .card-body {
  padding-top: 0;
}

.seasonal-analytics-chart {
  height: calc(100vh - 30px - 90.3px - 90px - 21px - 2rem - 36px);
}
</style>
<style>
.seasonal-analytics-chart-card .g-xtitle,
.seasonal-analytics-chart-card .g-x2title,
.seasonal-analytics-chart-card .g-x3title {
  display: none !important;
}
</style>
