import { render, staticRenderFns } from "./PalettePickerV2.vue?vue&type=template&id=48db3230&scoped=true&"
import script from "./PalettePickerV2.vue?vue&type=script&lang=js&"
export * from "./PalettePickerV2.vue?vue&type=script&lang=js&"
import style0 from "./PalettePickerV2.vue?vue&type=style&index=0&id=48db3230&lang=scss&scoped=true&"
import style1 from "./PalettePickerV2.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "48db3230",
  null
  
)

export default component.exports