<template>
  <div>
    <b-card class="seasonal-analytics-card custom-card">
      <b-row class="flex-nowrap d-flex align-items-center">
        <b-col cols="auto">
          <b-form-group class="mb-0" style="min-width: 300px">
            <DatasourceSelect v-model="datasourceSelected" mode="single" :disabled="showOverlay" />
          </b-form-group>
        </b-col>

        <b-col cols="auto">
          <b-form-group class="mb-0">
            <option-chart :label="$t('option_chart')" :options="typeList" :typeList="typeList" :nameComponent="$options.name" />
          </b-form-group>
        </b-col>

        <b-col cols="auto" class="ml-auto d-flex align-items-center">
          <SummaryData :chartData="chartData.data" />
          <Downloader ref="refDownload" :downloadImage="downloadImage" :downloadHtml="downloadHtml" :downloadCsv="downloadCsv" />
        </b-col>
      </b-row>
    </b-card>
    <b-overlay :show="showOverlay" variant="transparent" opacity="0.85" blur="2px" rounded="sm">
      <template #overlay>
        <div class="text-center">
          <div class="mb-1 text-center">
            <b-spinner />
          </div>
          <b-button variant="outline-danger" size="sm" aria-describedby="cancel-label"> {{ $t('terminate') }} </b-button>
        </div>
      </template>
      <SeasonalAnalyticsChartComponent ref="chart" :showYear="showYear" :chart="chart" :nameComponent="$options.name" />
    </b-overlay>

    <Timeplayer ref="timeplayer" v-model="date" :liveMode="liveMode" :moveToEnd="moveToEnd" />
  </div>
</template>

<script>
const _ = require('lodash')
const dayjs = require('dayjs')
import SelectDatasourceMixin from '@/mixins/SelectDatasourceMixin'
import PausePlayerMixin from '@/mixins/PausePlayerMixin'
import ToastificationContent from '@/@core/components/toastification/ToastificationContent.vue'
import Timeplayer from '../../common/Timeplayer.vue'
import DatasourceSelect from '../../common/DatasourceSelect/DatasourceSelect.vue'
import Downloader from '../../common/Downloader.vue'
import SummaryData from '../../common/SummaryData.vue'
import RadioGroup from '../../common/RadioGroup.vue'
import CustomInput from '../../common/CustomInput.vue'
import SeasonalAnalyticsChartComponent from './SeasonalAnalyticsChartComponent.vue'
import ChartYearMixin from '@/mixins/ChartYearMixin'
import { OPTION_PLOTLY_PALETTE } from '@/constants/colors'
import OptionChart from '../../common/OptionChartPlotly.vue'
const setMonths = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
const TYPE_LIST = [
  { value: 'lines', text: 'lines_title', icon: require('/static/images/timeseries/lines.svg').default },
  { value: 'markers', text: 'markers_title', icon: require('/static/images/timeseries/markers.svg').default },
  { value: 'lines+markers', text: 'lines_markers_title', icon: require('/static/images/timeseries/lines+markers.svg').default },
]

export default {
  name: 'seasonal_analytics',
  components: { CustomInput, SeasonalAnalyticsChartComponent, Timeplayer, DatasourceSelect, Downloader, SummaryData, RadioGroup, OptionChart },
  mixins: [SelectDatasourceMixin, PausePlayerMixin, ToastificationContent, ChartYearMixin],
  mounted() {},
  data() {
    return {
      typeList: TYPE_LIST,
      chart: {},
      showOverlay: false,
      moveToEnd: 0,
    }
  },
  computed: {
    date: {
      get() {
        return this.$store.state.tabs[this.$options.name].date
      },
      set(date) {
        this.$store.commit(`tabs/SET_${this.$options.name.toUpperCase()}`, { date })
      },
    },
    mode: {
      get() {
        return this.$store.state.tabs[this.$options.name].mode
      },
      set(mode) {
        this.$store.commit(`tabs/SET_${this.$options.name.toUpperCase()}`, { mode })
      },
    },
    frequency: {
      get() {
        return this.$store.state.tabs[this.$options.name].frequency
      },
      set(frequency) {
        this.$store.commit(`tabs/SET_${this.$options.name.toUpperCase()}`, { frequency })
      },
    },
    modeChoices() {
      return [
        { text: this.$i18n.t('STL'), value: 'loess', tooltip: 'stl_tittle' },
        // { text: this.$i18n.t('TSA Additive'), value: 'additive', tooltip: 'tsa_additive_title' },
        // { text: this.$i18n.t('TSA Multiplicative'), value: 'multiplicative', tooltip: 'tsa_multiplicative_title' },
      ]
    },
    chartFlag() {
      return `${this.chartData.data}__${this.frequency}__${this.mode}`
    },
    minChartDataLength() {
      if (this.mode === 'loess') {
        return Number(this.frequency)
      } else {
        return Number(this.frequency) * 2
      }
    },
    tempProject() {
      return this.$store.state.ecoplot.tempProject
    },
    markerColor() {
      return OPTION_PLOTLY_PALETTE.D3
    },
    showYear() {
      return this.$store.state.tabs[this.$options.name].year
    },
    resampleSeasonal() {
      return this.$store.state.tabs[this.$options.name].resampleSeasonal
    },
    liveMode() {
      try {
        let check = false
        if (!this.datasourceSelected) return false
        let arryIdDatasources = []
        const datasources = this.$store.state.datasource.datasources
        datasources.map((data) => {
          arryIdDatasources.push(data.id)
        })
        arryIdDatasources = _.unionBy(arryIdDatasources)
        this.datasourceSelected.map((data) => {
          let indexData = arryIdDatasources.indexOf(data.datasource)
          if (indexData > -1 && datasources[indexData].live) {
            check = true
            return check
          }
        })
        return check
      } catch {}
      return false
    },
    countLiveMode() {
      return this.$store.state.settings.countLiveMode
    },
  },
  asyncComputed: {
    chartData: {
      default: { data: [], dates: [] },
      async get() {
        let chartData = { data: [], dates: [] }

        // min date and max date (range case and single case)
        const minDate = this.date.length === 2 ? this.date[0] : this.date.length === 1 ? '0000-00-00 00:00:00' : ''
        const maxDate = this.date.length === 2 ? this.date[1] : this.date.length === 1 ? this.date[0] : ''
        const timeseriesDatasource = this.datasourceSelected.filter((ds) => ds.location)[0]
        if (timeseriesDatasource) {
          const { datasource: datasourceId, location, item } = timeseriesDatasource
          const locationsItems = [location + '-' + item]
          const records = await this.selectRangeByLocationsItems(datasourceId, locationsItems, minDate, maxDate)
          if (records && records[locationsItems] && records[locationsItems].length >= this.minChartDataLength) {
            chartData.dates = records.dates
            chartData.data = records[locationsItems]
          }
        }
        return chartData
      },
    },
  },
  watch: {
    datasourceSelected() {
      this.moveToEnd++
    },
    chartFlag() {
      if (this.selectedDatasources.length) {
        this.runExecutableSeasonalAnalytics()
      } else {
        this.chart = {}
      }
    },
    resampleSeasonal() {
      if (this.selectedDatasources.length) {
        this.runExecutableSeasonalAnalytics()
      } else {
        this.chart = {}
      }
    },
    countLiveMode() {
      if (this.selectedDatasources.length) {
        this.runExecutableSeasonalAnalytics()
      } else {
        this.chart = {}
      }
    },
  },
  methods: {
    runExecutableSeasonalAnalytics() {
      try {
        if (this.selectedDatasources.length && this.chartData.data.length) {
          // Step 1: Create an object to store the sum and count of data for each month
          const monthData = {}

          let data = _.cloneDeep(this.chartData.data)
          let date = _.cloneDeep(this.chartData.dates)

          // Step 2: Calculate the sum and count of data for each month
          for (let i = 0; i < date.length; i++) {
            // const month = date[i].slice(0, 7); // Extract the year-month part from the date
            const month = dayjs(date[i]).format('YYYY-MM')
            if (!monthData[month]) {
              monthData[month] = { sum: null, count: 0 }
            }

            if (data[i] || data[i] === 0) {
              monthData[month].sum += data[i]
              monthData[month].count++
            }
          }

          // Step 3: Calculate the average for each month and create the new arrays
          const newDate = []
          const newData = []
          for (const month in monthData) {
            const average = monthData[month].sum ? monthData[month].sum / monthData[month].count : null
            newDate.push(month + '-01 00:00:00') // Use the first day of the month as the date
            newData.push(average)
          }
          let seasonalDataBoxplot = {}
          if (this.resampleSeasonal === 'origin') {
            seasonalDataBoxplot.data = this.chartData.data
            seasonalDataBoxplot.date = this.chartData.dates
          }
          if (this.resampleSeasonal === 'monthlyAverage') {
            seasonalDataBoxplot.data = newData
            seasonalDataBoxplot.date = newDate
          }
          let dataOrigin = {
            data: this.chartData.data,
            date: this.chartData.dates,
          }

          let dataAverage = {
            data: newData,
            date: newDate,
          }

          let objSeasonalData = [
            {
              nameChart: 'timeseriesYear',
              data: this.resampleSeasonal === 'origin' ? dataOrigin : dataAverage,
            },
            {
              nameChart: 'timeseriesMonth',
              data: dataAverage,
            },
            {
              nameChart: 'boxplot',
              data: this.resampleSeasonal === 'origin' ? dataOrigin : dataAverage,
            },
          ]

          this.setDataForChart(objSeasonalData)
        }
      } catch (error) {}
    },

    setDataForChart(objSeasonalData) {
      try {
        let results = []
        // Create an array of data grouped by year and month
        const timeseriesYear = { nameChart: 'timeseriesYear', data: {} }
        const timeseriesMonth = { nameChart: 'timeseriesMonth', data: {} }
        const dataByBoxplot = { nameChart: 'dataByBoxplot', data: {} }

        for (let i = 0; i < objSeasonalData.length; i++) {
          let dataInput = objSeasonalData[i].data['data']
          let dateInput = objSeasonalData[i].data['date']

          // Convert 'dateInput' to JavaScript Date objects
          const dateObjects = dateInput.map((dateString) => new Date(dateString))

          // Create an array of year names for sorting
          // const setYears = [...new Set(dateObjects.map((date) => date.getFullYear()))];

          if (objSeasonalData[i].nameChart === 'timeseriesYear') {
            dateObjects.forEach((date, index) => {
              const year = date.getFullYear()
              const month = setMonths[date.getMonth()]

              var formatDateByYear = dayjs(date).format('MMMM-DD HH:mm:ss')

              if (!timeseriesYear.data[year]) {
                timeseriesYear.data[year] = { date: [], data: [] }
              }
              timeseriesYear.data[year].date.push(formatDateByYear)
              timeseriesYear.data[year].data.push(dataInput[index])
            })
          }
          if (objSeasonalData[i].nameChart === 'timeseriesMonth') {
            dateObjects.forEach((date, index) => {
              const year = date.getFullYear()
              const month = setMonths[date.getMonth()]

              if (!timeseriesMonth.data[month]) {
                timeseriesMonth.data[month] = { date: [], data: [] }
              }

              timeseriesMonth.data[month].date.push(year)
              timeseriesMonth.data[month].data.push(dataInput[index])
            })
          }
          if (objSeasonalData[i].nameChart === 'boxplot') {
            dateObjects.forEach((date, index) => {
              const year = date.getFullYear()
              const month = setMonths[date.getMonth()]

              if (!dataByBoxplot.data[month]) {
                dataByBoxplot.data[month] = { date: [], data: [] }
              }

              dataByBoxplot.data[month].date.push(year)
              dataByBoxplot.data[month].data.push(dataInput[index])
            })
          }

          // // Sort the data by month
          // Object.keys(dataByYear.data).forEach((year) => {
          //   const sortedIndexes = dataByYear.data[year].date.map((month) => setMonths.indexOf(month)).sort((a, b) => a - b);
          //   dataByYear.data[year].date = sortedIndexes.map((index) => setMonths[index]);
          //   dataByYear.data[year].data = sortedIndexes.map((index) => dataByYear.data[year].data[index]);
          // });

          // // Sort the data by year
          // Object.keys(dataByMonth.data).forEach((month) => {
          //   const sortedIndexes = dataByMonth.data[month].date.map((year) => setYears.indexOf(year)).sort((a, b) => a - b);

          //   dataByMonth.data[month].date = sortedIndexes.map((index) => setYears[index]);
          //   dataByMonth.data[month].data = sortedIndexes.map((index) => dataByMonth.data[month].data[index]);
          // });
        }
        results = [timeseriesYear, timeseriesMonth, dataByBoxplot]

        let resultSeasonalData = { status: 'success', data: results }
        this.showChart(resultSeasonalData)
      } catch (error) {
        let resultSeasonalData = { status: 'error', message: error }
        this.showChart(resultSeasonalData)
        console.error('Error:', error)
      }
    },
    showChart(seasonalData) {
      if (seasonalData.status === 'error') {
        this.$toast({ component: ToastificationContent, props: { title: this.$t('calculated_failed'), text: seasonalData.message, icon: 'SlashIcon', variant: 'danger' } })
      } else if (seasonalData.status === 'success') {
        if (Object.keys(seasonalData.data[0].data).length !== 0) {
          this.chart = { data: seasonalData.data }
        } else {
          this.chart = { data: {} }
        }
      }
    },
    downloadHtml() {
      this.$refs.chart.asHtml(this.tempProject.name + '_SeasonalAnalytics')
    },
    downloadImage() {
      this.$refs.chart.asImage(this.tempProject.name + '_SeasonalAnalytics')
    },
    async downloadCsv() {
      let data = await this.getDataCSV()
      this.$refs.chart.asCSV(this.tempProject.name + '_SeasonalAnalytics', data)
    },
    async getDataCSV() {
      let dataCSV = ''
      let id = 'ID'
      let item = 'ITEM'
      let unit = 'UNIT'
      let dataLines = []
      let lineCSV = ''
      let recordsDate = []
      // Min date and max date (range case and single case)
      const minDate = this.date.length === 2 ? this.date[0] : this.date.length === 1 ? '0000-00-00 00:00:00' : ''
      const maxDate = this.date.length === 2 ? this.date[1] : this.date.length === 1 ? this.date[0] : ''
      const datasourceGrouped = _.groupBy(this.datasourceSelected, (d) => d.datasource)
      // calculator commma length
      for (const datasourceId in datasourceGrouped) {
        const locationsItems = _.map(datasourceGrouped[datasourceId], (data) => {
          let unitTemp = ','
          try {
            unitTemp = `,${this.$db[data.datasource].items[data.item].unit}`
          } catch {}
          id += ',' + data.location
          item += ',' + data.item
          unit += unitTemp
          return data.location + '-' + data.item
        })
        const records = await this.selectRangeByLocationsItems(datasourceId, locationsItems, minDate, maxDate, this.scale)
        if (records.dates && records.dates != null) {
          recordsDate = recordsDate[0] && new Date(recordsDate[0]) > new Date(records.dates[0]) ? [...new Set([...records.dates, ...recordsDate])] : [...new Set([...recordsDate, ...records.dates])]
        }
      }
      // Group by datasource
      let dataSort = {}
      for (const datasourceId in datasourceGrouped) {
        const dataItems = _.cloneDeep(datasourceGrouped[datasourceId])
        let dataItemsSort
        try {
          dataItemsSort = _.sortBy(dataItems, ['item', 'location'])
        } catch {
          dataItemsSort = dataItems
        }
        dataSort[datasourceId] = dataItemsSort
        const locationsItems = _.map(dataItemsSort, (data) => {
          return data.location + '-' + data.item
        })
        const records = await this.selectRangeByLocationsItems(datasourceId, locationsItems, minDate, maxDate, this.scale)
        recordsDate.forEach((date, index) => {
          dataLines[index] = dataLines[index] && dataLines[index] != '' ? dataLines[index] : date
          for (let m = 0; m < locationsItems.length; m++) {
            let id = records.dates.indexOf(date)
            if (id != -1) {
              dataLines[index] += ',' + records[locationsItems[m]][id]
            } else {
              dataLines[index] += ','
            }
          }
        })
      }
      dataLines.forEach((line) => {
        lineCSV += line + '\r\n'
      })
      if (lineCSV != '') {
        dataCSV += id + '\r\n' + item + '\r\n' + unit + '\r\n' + lineCSV
      }
      return dataCSV
    },
    // downloadCsv() {
    //   let data = (this.chart || {}).data || {};
    //   if (!this.date.length) return;
    //   if (Object.keys(data).length === 0) return;

    //   let csvContent = 'data:text/csv;charset=utf-8,';
    //   csvContent += 'No,Date,Original,Seasonal,Trend,Remainder\r\n';
    //   data.dates.forEach((date, index) => {
    //     csvContent += '' + (index + 1) + ',' + date + ',' + data.original[index] + ',' + data.seasonal[index] + ',' + data.trend[index] + ',' + data.remainder[index] + '\r\n';
    //   });
    //   this.$refs.refDownload.download({
    //     filename: this.tempProject.name + '_SeasonalAnalytics.csv',
    //     data: csvContent,
    //   });
    // },
  },
}
</script>

<style>
.seasonal-analytics-card .cl_frequency {
  width: 65px;
}
</style>
