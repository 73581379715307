<template>
  <div>
    <div v-if="nameComponent === 'seasonal_analytics'">
      <div v-for="(value, key) in listColors" :key="key">
        <button type="button" class="palette-picker-container">
          <b-form-input :placeholder="placeholder" :size="size" readonly style="opacity: 1" />
          <div class="palette-picker" ref="palette_picker">
            <div class="sample-palette" ref="sample_palette">
              <div class="custom-scrollbar">
                <div class="custom-scrollbar_item" v-for="(sample, index) in samples" :key="index" @click="applySampleSeasonal(key, sample)" :title="index">
                  <div class="custom-scrollbar_item-value" v-if="sample.length > 0" v-for="(color, indexSample) in sample" :key="indexSample" :style="{ background: color }"></div>
                </div>
              </div>
              <div class="btn-resets d-flex justify-content-center">
                <b-button class="btn-reset" style="flex: 1" variant="outline-primary" @click="resetMarkerColorSeasonal(key)">{{ $t('reset') }}</b-button>
                <b-button class="btn-save" style="flex: 1" variant="outline-primary" @click="saveMarkerColorSeasonal(key)">{{ $t('save') }}</b-button>
              </div>
            </div>
            <div class="live-edit">
              <div class="live-edit-item custom-scrollbar">
                <b-form-group v-for="(color, index) in value" :key="index">
                  <ColorPicker :value="color" @input="value.splice(index, 1, $event)" :placeholder="'Color' + (index + 1)" size="sm" :key="colorPickerKey" :left="'-10px'" />
                </b-form-group>
              </div>
            </div>
          </div>
          <div class="palette-display">
            <div v-for="(color, index) in value" :key="index" :style="{ background: color }"></div>
          </div>
        </button>
      </div>
    </div>
    <div v-else>
      <button type="button" class="palette-picker-container">
        <b-form-input :placeholder="placeholder" :size="size" readonly style="opacity: 1" />
        <div class="palette-picker" ref="palette_picker">
          <div class="sample-palette" ref="sample_palette">
            <div class="custom-scrollbar">
              <div class="custom-scrollbar_item" v-for="(sample, index) in samples" :key="index" @click="applySample(sample)" :title="index">
                <div class="custom-scrollbar_item-value" v-if="sample.length > 0" v-for="(color, indexSample) in sample" :key="indexSample" :style="{ background: color }"></div>
              </div>
            </div>
            <div class="btn-resets d-flex justify-content-center">
              <b-button class="btn-reset" style="flex: 1" variant="outline-primary" @click="resetMarkerColor">{{ $t('reset') }}</b-button>
              <b-button class="btn-save" style="flex: 1" variant="outline-primary" @click="saveMarkerColor">{{ $t('save') }}</b-button>
            </div>
          </div>
          <div class="live-edit">
            <div class="live-edit-item custom-scrollbar">
              <b-form-group v-for="(color, index) in colors" :key="index">
                <ColorPicker :value="color" @input="colors.splice(index, 1, $event)" :placeholder="'Color' + (index + 1)" size="sm" :key="colorPickerKey" :left="'-10px'" />
              </b-form-group>
            </div>
          </div>
        </div>
        <div class="palette-display">
          <div v-for="(color, index) in colors" :key="index" :style="{ background: color }"></div>
        </div>
      </button>
    </div>
  </div>
</template>

<script>
import { Chrome } from 'vue-color';
import VuePerfectScrollbar from 'vue-perfect-scrollbar';
import { OPTION_PLOTLY_PALETTE } from '@/constants/colors';
import ColorPicker from './ColorPicker2.vue';

export default {
  props: ['placeholder', 'size', 'nameComponent', 'selectTimeseries', 'timeDelay', 'moveCountPalettePicker'],
  components: { ColorPicker, VuePerfectScrollbar, Chrome },
  mounted() {
    if (this.nameComponent !== 'seasonal_analytics') {
      let colors = this.$store.state.tabs[this.nameComponent].colors;
      this.colors = colors && colors.length > 0 ? [...colors] : [...OPTION_PLOTLY_PALETTE.D3];
    } else {
      let colorsCycle = this.$store.state.tabs[this.nameComponent].colorsCycle;
      let colorsUnit = this.$store.state.tabs[this.nameComponent].colorsUnit;
      let colorsUnitBoxplot = this.$store.state.tabs[this.nameComponent].colorsUnitBoxplot;
      this.listColors.colorsCycle = colorsCycle && colorsCycle.length > 0 ? [...colorsCycle] : [...OPTION_PLOTLY_PALETTE.seasonalByYear];
      this.listColors.colorsUnit = colorsUnit && colorsUnit.length > 0 ? [...colorsUnit] : [...OPTION_PLOTLY_PALETTE.seasonalByMonth];
      this.listColors.colorsUnitBoxplot = colorsUnitBoxplot && colorsUnitBoxplot.length > 0 ? [...colorsUnitBoxplot] : [...OPTION_PLOTLY_PALETTE.seasonalByMonth];
    }
  },
  data() {
    return {
      listColors: {
        colorsCycle: [],
        colorsUnit: [],
        colorsUnitBoxplot: [],
      },
      colors: [],
      samples: OPTION_PLOTLY_PALETTE,
      colorPickerKey: 1,
      timeOutDelay: null,
    };
  },
  methods: {
    // function for seasonal analytics
    applySampleSeasonal(name, sample) {
      if (name === 'colorsCycle') this.listColors.colorsCycle = sample.slice();
      if (name === 'colorsUnit') this.listColors.colorsUnit = sample.slice();
      if (name === 'colorsUnitBoxplot') this.listColors.colorsUnitBoxplot = sample.slice();
      this.colorPickerKey++;
    },
    resetMarkerColorSeasonal(name) {
      if (name === 'colorsCycle') this.listColors.colorsCycle = [...OPTION_PLOTLY_PALETTE.seasonalByYear];
      if (name === 'colorsUnit') this.listColors.colorsUnit = [...OPTION_PLOTLY_PALETTE.seasonalByMonth];
      if (name === 'colorsUnitBoxplot') this.listColors.colorsUnitBoxplot = [...OPTION_PLOTLY_PALETTE.seasonalByMonth];
      this.saveMarkerColorSeasonal(name);
    },
    saveMarkerColorSeasonal(name) {
      if (name === 'colorsCycle') {
        let colorsCycle = [...this.listColors.colorsCycle];
        this.$store.commit(`tabs/SET_${this.nameComponent.toUpperCase()}`, { colorsCycle: colorsCycle });
      }
      if (name === 'colorsUnit') {
        let colorsUnit = [...this.listColors.colorsUnit];
        this.$store.commit(`tabs/SET_${this.nameComponent.toUpperCase()}`, { colorsUnit: colorsUnit });
      }
      if (name === 'colorsUnitBoxplot') {
        let colorsUnitBoxplot = [...this.listColors.colorsUnitBoxplot];
        this.$store.commit(`tabs/SET_${this.nameComponent.toUpperCase()}`, { colorsUnitBoxplot: colorsUnitBoxplot });
      }
    },

    // ------------------------------------

    applySample(sample) {
      this.colors = sample.slice();
      this.colorPickerKey++;
    },
    resetMarkerColor() {
      this.colors = [...OPTION_PLOTLY_PALETTE.D3];
      this.saveMarkerColor();
    },
    saveMarkerColor() {
      let colors = [...this.colors];
      if (this.selectTimeseries) {
        let arrayDatasourceSelect = [...this.$store.state.tabs[this.nameComponent].arrayDatasourceSelect];
        switch (this.selectTimeseries) {
          case 'xy1': {
            arrayDatasourceSelect[0].colors = colors;
            break;
          }
          case 'xy2': {
            arrayDatasourceSelect[1].colors = colors;
            break;
          }
          case 'xy3': {
            arrayDatasourceSelect[2].colors = colors;
            break;
          }
          case 'xy4': {
            arrayDatasourceSelect[3].colors = colors;
            break;
          }
        }
        this.$store.commit(`tabs/SET_${this.nameComponent.toUpperCase()}`, { arrayDatasourceSelect });
      }
      this.$store.commit(`tabs/SET_${this.nameComponent.toUpperCase()}`, { colors });
    },
  },
  watch: {
    selectTimeseries(selectTimeseries) {
      clearTimeout(this.timeOutDelay);
      this.timeOutDelay = setTimeout(() => {
        let colors;
        if (selectTimeseries) {
          let arrayDatasourceSelect = [...this.$store.state.tabs[this.nameComponent].arrayDatasourceSelect];
          switch (selectTimeseries) {
            case 'xy1': {
              colors = arrayDatasourceSelect[0].colors;
              break;
            }
            case 'xy2': {
              colors = arrayDatasourceSelect[1].colors;
              break;
            }
            case 'xy3': {
              colors = arrayDatasourceSelect[2].colors;
              break;
            }
            case 'xy4': {
              colors = arrayDatasourceSelect[3].colors;
              break;
            }
            default: {
              colors = [...this.$store.state.tabs[this.nameComponent].colors];
              break;
            }
          }
        }
        this.colors = colors && colors.length > 0 ? [...colors] : [...OPTION_PLOTLY_PALETTE.D3];
      }, this.timeDelay);
    },
    moveCountPalettePicker() {
      if (this.nameComponent !== 'seasonal_analytics') {
        let colors = this.$store.state.tabs[this.nameComponent].colors;
        this.colors = colors && colors.length > 0 ? [...colors] : [...OPTION_PLOTLY_PALETTE.D3];
      } else {
        let colorsCycle = this.$store.state.tabs[this.nameComponent].colorsCycle;
        let colorsUnit = this.$store.state.tabs[this.nameComponent].colorsUnit;
        let colorsUnitBoxplot = this.$store.state.tabs[this.nameComponent].colorsUnitBoxplot;
        this.listColors.colorsCycle = colorsCycle && colorsCycle.length > 0 ? [...colorsCycle] : [...OPTION_PLOTLY_PALETTE.seasonalByYear];
        this.listColors.colorsUnit = colorsUnit && colorsUnit.length > 0 ? [...colorsUnit] : [...OPTION_PLOTLY_PALETTE.seasonalByMonth];
        this.listColors.colorsUnitBoxplot = colorsUnitBoxplot && colorsUnitBoxplot.length > 0 ? [...colorsUnitBoxplot] : [...OPTION_PLOTLY_PALETTE.seasonalByMonth];
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.palette-picker-container {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  border: none;
  background-color: transparent;
  cursor: default;
  outline: none;
}

.palette-picker-container .palette-picker {
  position: absolute;
  right: 0;
  top: 100%;
  display: none;
  z-index: 2;
  background: #fff;
  border-radius: 4px;
  box-shadow: 0 0 0 1px rgb(0 0 0 / 15%), 0 8px 16px rgb(0 0 0 / 15%);
  padding: 15px 0;
  width: 100%;
}

.palette-picker-container:focus .palette-picker {
  display: flex;
}

.palette-picker-container:focus-within .palette-picker {
  display: flex;
}

.palette-picker .live-edit {
  width: 100%;
  padding: 0 5px 0 0px;
}

.palette-picker .live-edit .form-group:last-child {
  margin-bottom: 0;
}

.palette-picker .sample-palette {
  width: 100%;
  height: 250px;
  margin-top: -9px;
}

.palette-picker .custom-scrollbar {
  height: 100%;
  padding: 0 10px 0 10px;
  overflow: scroll;
}

.palette-picker .sample-palette-item {
  height: 10px;
  margin-top: 10px;
  cursor: pointer;
}

.palette-picker .sample-palette-item:first-child {
  margin-top: 0;
}

.palette-display {
  position: absolute;
  top: 6px;
  bottom: 6px;
  left: 12px;
  right: 12px;
  pointer-events: none;
}

.palette-picker {
  height: 300px;
}

.btn-resets {
  padding: 0 10px 0 10px;
}

.btn-reset {
  flex: 1;
  padding: 8px;
  font-size: 10px;
  margin-right: 3px;
}
.btn-save {
  flex: 1;
  padding: 8px;
  font-size: 10px;
}
.custom-scrollbar {
  &::-webkit-scrollbar {
    width: 3px;
  }

  &_item {
    display: flex;
    align-items: center;
    overflow: hidden;

    &-value {
      flex: 1;
      height: 10px;
      margin-top: 10px;
      cursor: pointer;
      flex-shrink: 0;
    }

    .form-group {
      margin-bottom: 0.5rem !important;
    }
  }
}

.palette-picker .live-edit-item {
  max-height: 280px;
  overflow: scroll;
}

.palette-display {
  display: flex;

  div {
    flex: 1;
    // height: 10px;
  }
}
</style>

<style lang="scss">
.palette-picker-container .ColorPicker-container input {
  border-radius: 0.357rem !important;
}
.option-content .color-picker-container {
  input {
    padding-right: 1.7rem;
    padding-left: 0.32rem;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 1;
    display: -webkit-box;
    -webkit-box-orient: vertical;
  }
}

.color-picker-chorme {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}
</style>
